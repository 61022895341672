import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import { Grid, Box, FormControl, TextField, Select, MenuItem, Button } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useParams } from 'react-router-dom'
import * as fn from '../../../services/default.service'
import { getHttp, postHttp } from '../../../services/http'
import { fetchStart, fetchSuccess } from '../../../redux/actions'
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
import _ from 'lodash'
const filter = createFilterOptions()
const useStyles = makeStyles(theme => ({
  formControl: {
    height: '30px',
    width: '100%',
    '& .MuiInputBase-formControl': {
      height: '30px',
    },
    '& .MuiSelect-select': {
      padding: '0 10px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 5px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -5px) scale(.5)',
    },

    '& .MuiFormHelperText-root.Mui-error': {
      color: 'red',
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '10px',
      margin: '0',
      color: 'transparent',
    },
    '& input': {
      fontSize: '14px',
      height: '30px',
      padding: '0px 10px',
    },
    '& .Mui-disabled fieldset': {
      background: '#ebebeb',
    },
    '& fieldset': { borderColor: '#cb1b67 !important' },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
  },
  Autocomplete: {
    height: '30px',
    width: '80px',
    margin: '0 10px 0 0',
    '& .MuiFormControl-root,.MuiInputBase-root': {
      height: '30px',
    },
    '& .MuiFormControl-root': {
      margin: '0',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0 10px',
      '& input': {
        padding: '0  !important',
        fontSize: '14px',
      },
    },
    '& fieldset': { borderColor: '#cb1b67 !important' },
    '& .MuiAutocomplete-clearIndicator': {
      color: '#cb1b67',
    },
  },
  lbl: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '120px',
  },
  backButton: {
    border: '1px solid #cb1b67',
    backgroundColor: '#FFF',
    '& .MuiButton-label': {
      color: '#cb1b67',
    },
    '&:hover': {
      border: '1px solid #cb1b67',
      backgroundColor: '#FFF',
    },
  },
}))
const Info = () => {
  const { setAlertDialog, setAlert } = useContext(AppContext)
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { Id } = useParams()
  const [titleTH, settitleTH] = React.useState([])
  const [titleEng, settitleEng] = React.useState([])
  const [data, setdata] = React.useState({
    id: null,
    modefy: false,
    editPassword: false,
    scopusID: {
      invalid: false,
      text: '',
      msg: '',
    },
    title: {
      invalid: false,
      text: '',
      msg: '',
    },
    firstName: {
      invalid: false,
      text: '',
      msg: '',
    },
    lastName: {
      invalid: false,
      text: '',
      msg: '',
    },
    firstInitial: {
      invalid: false,
      text: '',
      msg: '',
    },
    titleEng: {
      invalid: false,
      text: '',
      msg: '',
    },
    firstNameEng: {
      invalid: false,
      text: '',
      msg: '',
    },
    lastNameEng: {
      invalid: false,
      text: '',
      msg: '',
    },
    type: {
      invalid: false,
      text: 0,
      msg: '',
    },
    affications: {
      invalid: false,
      text: '',
      msg: '',
    },
  })

  const onChange = e => {
    let name = e.target.name
    data.modefy = true
    let item = data[name]
    item.text = e.target.value
    item.invalid = false
    setdata({ ...data })
    // console.log(data)
  }
  const onChangeTitle = (n, v) => {
    let item = data[n]
    item.text = v
    item.invalid = false
    item.msg = ''
    setdata({ ...data })
    // console.log(data)
  }

  const getLecturersById = async (isCurrent, Id) => {
    dispatch(fetchStart())
    let user = await getHttp('/lecturers/' + Id, dispatch)
    if (user.data.status) {
      let tbLecturers = user.data.tbLecturers
      if (isCurrent) {
        setdata({
          ...data,
          modefy: false,
          scopusID: {
            text: tbLecturers.scopusID,
          },

          title: {
            text: fn.IsNullOrEmpty(tbLecturers.title) ? '' : { title: tbLecturers.title },
          },
          firstName: {
            text: tbLecturers.firstName,
          },
          lastName: {
            text: tbLecturers.lastName,
          },
          firstInitial: {
            text: tbLecturers.firstInitial,
          },
          titleEng: {
            text: fn.IsNullOrEmpty(tbLecturers.titleEng) ? '' : { title: tbLecturers.titleEng },
          },
          firstNameEng: {
            text: tbLecturers.firstNameEng,
          },
          lastNameEng: {
            text: tbLecturers.lastNameEng,
          },
          type: {
            text: tbLecturers.type,
          },
          affications: {
            text: tbLecturers.affications,
          },
        })
      }
    }
    dispatch(fetchSuccess())
  }
  const getTitle = async isCurrent => {
    let title = await postHttp('/lecturers/title')
    if (title.data.status) {
      settitleTH(title.data.titleTH)
      settitleEng(title.data.titleEng)
    } else {
      settitleTH([])
      settitleEng([])
    }
  }
  const dosave = async callback => {
    if (valid()) {
      let item = {
        id: data.id,
        scopusID: data.scopusID.text,
        title: fn.IsNullOrEmpty(data.title.text)
          ? null
          : typeof data.title.text === 'string'
          ? data.title.text
          : data.title.text.title,
        firstName: data.firstName.text,
        lastName: data.lastName.text,
        titleEng: fn.IsNullOrEmpty(data.titleEng.text)
          ? null
          : typeof data.titleEng.text === 'string'
          ? data.titleEng.text
          : data.titleEng.text.title,
        firstNameEng: data.firstNameEng.text,
        lastNameEng: data.lastNameEng.text,
        type: data.type.text,
        affications: data.affications.text,
        firstInitial: data.firstInitial.text,
      }
      dispatch(fetchStart())
      let _save = await postHttp('/lecturers', item)
      if (_save.status) {
        if (_save.data.status) {
          if (callback !== undefined) {
            callback()
          } else {
            if (fn.IsNullOrEmpty(data.id)) {
              data.id = _save.data.id
              setdata({ ...data })
              window.history.replaceState(null, null, '/manageLecturersInfo/' + _save.data.id)
              getLecturersById(true, _save.data.id)
              getTitle()
            } else {
              getLecturersById(true, data.id)
              getTitle()
            }
            setAlert({ open: true, type: 'success', msg: 'บันทึกข้อมูลสำเร็จ' })
          }
        } else {
          if (_save.data.isDupdate === true) {
            _.map([..._save.data.Dupdate], e => {
              let item = data[e]
              item.invalid = true
              item.msg =
                e === 'scopusID'
                  ? 'Scopus ID ซ้ำ'
                  : 'firstName,'.includes(e)
                  ? 'ชื่อและนามสกุลซ้ำ'
                  : 'lastName,'.includes(e)
                  ? 'ชื่อและนามสกุลซ้ำ'
                  : 'firstNameEng,'.includes(e)
                  ? 'ชื่อและนามสกุลภาษาอังกฤษซ้ำ'
                  : 'ชื่อและนามสกุลภาษาอังกฤษซ้ำ'
              setdata({ ...data })
            })
          } else {
            setAlertDialog({
              open: true,
              type: 'alert',
              msg: 'ลบข้อมูล ไม่สำเร็จ',
              Close: even => {
                even()
              },
            })
          }
        }
      } else {
        setAlertDialog({
          open: true,
          type: 'alert',
          msg: 'ลบข้อมูล ไม่สำเร็จ',
          Close: even => {
            even()
          },
        })
      }
      dispatch(fetchSuccess())
    }
  }
  const valid = () => {
    let valid = !0

    if (fn.IsNullOrEmpty(data.firstNameEng.text)) {
      let firstNameEng = data.firstNameEng
      firstNameEng.msg = 'กรุณาระบุชื่อภาษาอังกฤษ'
      firstNameEng.invalid = true
      valid = !1
      setdata({ ...data, firstNameEng: firstNameEng })
    }
    if (fn.IsNullOrEmpty(data.lastNameEng.text)) {
      let lastNameEng = data.lastNameEng
      lastNameEng.msg = 'กรุณาระบุนามสกุลภาษาอังกฤษ'
      lastNameEng.invalid = true
      valid = !1
      setdata({ ...data, lastNameEng: lastNameEng })
    }
    if (fn.IsNullOrEmpty(data.firstInitial.text)) {
      let firstInitial = data.firstInitial
      firstInitial.msg = 'ชื่อย่อจาก Scopus'
      firstInitial.invalid = true
      valid = !1
      setdata({ ...data, firstInitial: firstInitial })
    }
    return valid
  }
  useEffect(() => {
    let isCurrent = true
    if (!fn.IsNullOrEmpty(Id)) {
      data.id = Id
      setdata({ ...data })
      getLecturersById(isCurrent, Id)
    }
    getTitle(isCurrent)
    return () => {
      isCurrent = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const breadcrumbs = [
    {
      label: (
        <Box style={{ display: 'flex' }}>
          <Button
            className={classes.backButton}
            variant="contained"
            color="primary"
            onClick={e => {
              e.preventDefault()
              if (data.modefy) {
                setAlertDialog({
                  open: true,
                  type: 'confirm',
                  msg: 'มีการแก้ไขต้องการบันทึก ใช่หรือไม่?',
                  Close: e => {
                    e()
                    history.push('/manageLecturers')
                  },
                  Ok: e => {
                    e()
                    dosave(() => {
                      history.push('/manageLecturers')
                    })
                  },
                })
              } else {
                history.push('/manageLecturers')
              }
            }}>
            กลับ
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 10px' }}
            onClick={e => {
              e.preventDefault()
              dosave()
            }}>
            บันทึก
          </Button>
        </Box>
      ),
    },
  ]
  return (
    <PageContainer heading={<IntlMessages id="pages.manageLecturersInformation" />} breadcrumbs={breadcrumbs}>
      <GridContainer style={{ padding: '0 15px 0 65px' }}>
        {/* <Grid item xs={12} style={{ padding: "0" }}> */}
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>Scopus ID</Box>
            <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth: '400px' }}>
              <TextField
                id="input-scopusID"
                name="scopusID"
                type="text"
                onChange={onChange}
                value={data.scopusID.text}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
                placeholder="Scopus ID"
                error={data.scopusID.invalid}
                helperText={data.scopusID.msg}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>
              ชื่อย่อจาก Scopus <span style={{ color: 'red' }}>*</span>
            </Box>
            <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth: '400px' }}>
              <TextField
                id="input-firstInitial"
                name="firstInitial"
                type="text"
                onChange={onChange}
                value={data.firstInitial.text}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
                placeholder="ชื่อย่อจาก Scopus"
                error={data.firstInitial.invalid}
                helperText={data.firstInitial.msg}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>ชื่อ</Box>
            <Box style={{ display: 'flex', width: 'calc(100% - 120px)' }}>
              <Autocomplete
                id="title"
                freeSolo
                value={data.title.text}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    onChangeTitle('title', newValue)
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    onChangeTitle('title', newValue.inputValue)
                  } else {
                    onChangeTitle('title', newValue)
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)

                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: `Add "${params.inputValue}"`,
                    })
                  }

                  return filtered
                }}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue
                  }
                  // Regular option
                  return option.title
                }}
                renderOption={option => option.title}
                options={titleTH}
                renderInput={params => <TextField {...params} placeholder="title" margin="normal" variant="outlined" />}
                className={classes.Autocomplete}
              />
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: 'calc(100% - 90px)', maxWidth: '310px' }}>
                <TextField
                  id="input-firstName"
                  name="firstName"
                  type="text"
                  onChange={onChange}
                  value={data.firstName.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="ชื่อ"
                  error={data.firstName.invalid}
                  helperText={data.firstName.msg}
                />
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>นาสกุล</Box>
            <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth: '400px' }}>
              <TextField
                id="input-lastName"
                name="lastName"
                type="text"
                onChange={onChange}
                value={data.lastName.text}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
                placeholder="นาสกุล"
                error={data.lastName.invalid}
                helperText={data.lastName.msg}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>
              ชื่อภาษาอังกฤษ <span style={{ color: 'red' }}>*</span>
            </Box>
            <Box style={{ display: 'flex', width: 'calc(100% - 120px)' }}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={data.titleEng.text}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    onChangeTitle('titleEng', newValue)
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    onChangeTitle('titleEng', newValue.inputValue)
                  } else {
                    onChangeTitle('titleEng', newValue)
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)

                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: `Add "${params.inputValue}"`,
                    })
                  }

                  return filtered
                }}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue
                  }
                  // Regular option
                  return option.title
                }}
                renderOption={option => option.title}
                options={titleEng}
                renderInput={params => <TextField {...params} placeholder="titleEng" margin="normal" variant="outlined" />}
                className={classes.Autocomplete}
              />
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: 'calc(100% - 90px)', maxWidth: '310px' }}>
                <TextField
                  id="input-firstNameEng"
                  name="firstNameEng"
                  type="text"
                  onChange={onChange}
                  value={data.firstNameEng.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="ชื่อภาษาอังกฤษ"
                  error={data.firstNameEng.invalid}
                  helperText={data.firstNameEng.msg}
                />
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>
              นามสกุลอังกฤษ <span style={{ color: 'red' }}>*</span>
            </Box>
            <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth: '400px' }}>
              <TextField
                id="input-lastNameEng"
                name="lastNameEng"
                type="text"
                onChange={onChange}
                value={data.lastNameEng.text}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
                placeholder="นามสกุลอังกฤษ"
                error={data.lastNameEng.invalid}
                helperText={data.lastNameEng.msg}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>
              ประเภท <span style={{ color: 'red' }}>*</span>
            </Box>
            <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth: '400px' }}>
              <Select
                value={data.type.text}
                onChange={onChange}
                variant="outlined"
                IconComponent={props => {
                  const rotate = props.className.toString().includes('iconOpen')
                  return !rotate ? (
                    <ExpandMoreIcon style={{ color: '#cb1b67' }} />
                  ) : (
                    <ExpandLessIcon style={{ color: '#cb1b67' }} />
                  )
                }}
                inputProps={{
                  name: 'type',
                  id: 'outlined-type-native-simple',
                }}>
                <MenuItem value={0} style={{ fontSize: '14px' }}>
                  อาจารย์
                </MenuItem>
                <MenuItem value={1} style={{ fontSize: '14px' }}>
                  นักวิจัย
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>Affiliations</Box>
            <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth: '400px' }}>
              <TextField
                id="input-affications"
                name="affications"
                type="text"
                onChange={onChange}
                value={data.affications.text}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
                placeholder="Affiliations"
                error={data.affications.invalid}
                helperText={data.affications.msg}
              />
            </FormControl>
          </Box>
        </Grid>
      </GridContainer>
      <Box style={{ position: 'absolute', bottom: '10px', color: 'red' }}>
        หมายเหตุ : ใช้ชื่อย่อจาก Scopus และนามสกุลอังกฤษ ในการค้นหาข้อมูลเพื่อแสดงในรายงาน
      </Box>
    </PageContainer>
  )
}

export default Info
