import React from 'react'

import { Redirect, Route, Switch } from 'react-router'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Error404 from './Pages/404'
import Login from './Auth/Login'
import Register from './Auth/Register'
import ForgotPasswordPage from './Auth/ForgotPassword'
import Resetpassword from './Auth/resetpassword'

import manageLecturers from './Pages/manageLecturers'
import manageLecturersinfo from './Pages/manageLecturers/info'
import citationReport from './Pages/citationReport'

import manageAdmin from './Pages/manageAdmin'
import manageAdmininfo from './Pages/manageAdmin/info'

import publicationreport from './Pages/publicationreport'
import { setAuthUser } from '../redux/actions/Auth'
import { getAuthUser } from '../services/http'

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth)
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const Routes = () => {
  const dispatch = useDispatch()

  let { authUser } = useSelector(({ auth }) => auth)
  const location = useLocation()
  if (authUser === null) {
    if (getAuthUser() !== null) {
      dispatch(setAuthUser(getAuthUser()))
    }

    if (location.pathname === '' || location.pathname === '/') {
      return <Redirect to={'/manageLecturers'} />
    } else if (authUser && location.pathname === '/signin') {
      return <Redirect to={'/manageLecturers'} />
    }
    console.clear()
    // console.info('%c หนีไป !!!!!! ', 'background: red; color: #FFF;font-size: 50px;')
  } else {
    if (location.pathname === '' || location.pathname === '/') {
      return <Redirect to={'/manageLecturers'} />
    } else if (authUser && location.pathname === '/signin') {
      return <Redirect to={'/manageLecturers'} />
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/manageLecturers" component={manageLecturers} />
        <RestrictedRoute path="/manageLecturersInfo/:Id?" component={manageLecturersinfo} />
        <RestrictedRoute path="/publicationreport" component={publicationreport} />
        <RestrictedRoute path="/citationreport" component={citationReport} />
        <RestrictedRoute path="/manageadmin" component={manageAdmin} />
        <RestrictedRoute path="/manageadminInfo/:Id?" component={manageAdmininfo} />

        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/resetpassword/:Id" component={Resetpassword} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  )
}

export default Routes
