import React from 'react'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 999999,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000036',
    '& .MuiCircularProgress-root': {
      color: '#cb1b67',
    },
  },
}))

const PageLoader = ({ message }) => {
  const classes = useStyles()

  return (
    <Box className={classes.circularProgressRoot}>
      <CircularProgress />
      {message}
    </Box>
  )
}

export default PageLoader
