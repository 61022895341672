import XLSX from 'xlsx-color'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import moment from 'moment'
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

const DownloadExcel = (data, name) => {
  // var wb = XLSX.utils.book_new()
  // XLSX.utils.book_append_sheet(wb, ws, 'sheet1')
  let ws1 = XLSX.utils.sheet_add_json(XLSX.utils.book_new().Sheets.sheet1, data.data, {
    skipHeader: true,
    origin: 'A10',
  })

  ws1['!cols'] = [
    { width: 10 },
    { width: 10 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 10 },
    { width: 20 },
  ]
  const font = {
    name: 'TH SarabunPSK',
  }
  ws1['A1'] = {
    t: 's',
    v: 'รายงานจำนวน Citations ต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS​',
    s: {
      font: font,
      alignment: {
        vertical: 'center',
        horizontal: 'center',
      },
    },
  }
  ws1['A2'] = {
    t: 's',
    v: 'วันที่ ' + data.startDate + ' ถึงวันที่ ' + data.endDate,
    s: {
      font: font,
      alignment: {
        vertical: 'center',
        horizontal: 'center',
      },
    },
  }
  ws1['A3'] = {
    t: 's',
    v: 'จำนวน Citations ต่ออาจารย์ และนักวิจัย ที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
    s: {
      font: font,
      alignment: {
        vertical: 'center',
        horizontal: 'right',
      },
    },
  }
  ws1['A4'] = {
    t: 's',
    v: 'จำนวน Citations ทั้งหมด ' + data.total + ' บทความ​',
    s: {
      font: font,
      alignment: {
        vertical: 'center',
        horizontal: 'right',
      },
    },
  }
  ws1['A5'] = {
    t: 's',
    v: 'จำนวนอาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS ' + data.totalresearcher + ' คน',
    s: {
      font: font,
      alignment: {
        vertical: 'center',
        horizontal: 'right',
      },
    },
  }
  ws1['A6'] = {
    t: 's',
    v: 'จำนวนการอ้างอิงผลงานวิจัย (Citations) ในฐานข้อมูล Scopus เท่ากับ ' + data.avgSCOPUS,
    s: {
      font: font,
      alignment: {
        vertical: 'center',
        horizontal: 'right',
      },
    },
  }
  ws1['A7'] = {
    t: 's',
    v: 'ข้อมูล ณ วันที่ ' + data.dateNow,
    s: {
      font: font,
      alignment: {
        vertical: 'center',
        horizontal: 'right',
      },
    },
  }
  // const hdborder = {
  //   right: {
  //     style: 'thin',
  //     color: 'f6eaf1',
  //   },
  //   left: {
  //     style: 'thin',
  //     color: 'f6eaf1',
  //   },
  //   top: {
  //     style: 'thin',
  //     color: 'f6eaf1',
  //   },
  //   bottom: {
  //     style: 'thin',
  //     color: 'f6eaf1',
  //   },
  // }
  const hds = {
    font: font,
    alignment: {
      vertical: 'center',
      horizontal: 'center',
    },
    fill: {
      fgColor: { rgb: 'e5e5e5' },
    },
    // border: hdborder,
  }
  ws1['A8'] = {
    t: 's',
    v: 'ลำดับที่​',
    s: hds,
  }
  ws1['B8'] = {
    t: 's',
    v: 'Publication',
    s: hds,
  }
  ws1['C8'] = {
    t: 's',
    // s: { border: hdborder },
  }
  ws1['D8'] = {
    t: 's',
    // s: { border: hdborder },
  }
  ws1['E8'] = {
    t: 's',
    // s: { border: hdborder },
  }

  ws1['F8'] = {
    t: 's',
    v: 'Cited By',
    s: hds,
  }
  ws1['I8'] = {
    t: 's',
    v: 'ฐานข้อมูลสากล',
    s: hds,
  }
  ws1['J8'] = {
    t: 's',
    v: 'แหล่งอ้างอิง',
    s: hds,
  }
  ws1['K8'] = {
    t: 's',
    v: 'Q economics',
    s: hds,
  }
  ws1['B9'] = {
    t: 's',
    v: 'Year',
    s: hds,
  }
  ws1['C9'] = {
    t: 's',
    v: 'Title',
    s: hds,
  }
  ws1['D9'] = {
    t: 's',
    v: 'Authors',
    s: hds,
  }
  ws1['E9'] = {
    t: 's',
    v: 'Journal',
    s: hds,
  }
  ws1['F9'] = {
    t: 's',
    v: 'Title',
    s: hds,
  }
  ws1['G9'] = {
    t: 's',
    v: 'Journal',
    s: hds,
  }
  ws1['H9'] = {
    t: 's',
    v: 'Authors',
    s: hds,
  }

  _.map([...data.data], (e, i) => {
    // const border = {
    //   right: {
    //     style: 'thin',
    //     color: 'f6eaf1',
    //   },
    //   left: {
    //     style: 'thin',
    //     color: 'f6eaf1',
    //   },
    //   top: {
    //     style: 'thin',
    //     color: 'f6eaf1',
    //   },
    //   bottom: {
    //     style: 'thin',
    //     color: 'f6eaf1',
    //   },
    // }
    let A = ws1['A' + (10 + i)]
    let B = ws1['B' + (10 + i)]
    let C = ws1['C' + (10 + i)]
    let D = ws1['D' + (10 + i)]
    let E = ws1['E' + (10 + i)]
    let F = ws1['F' + (10 + i)]
    let G = ws1['G' + (10 + i)]
    let H = ws1['H' + (10 + i)]
    let I = ws1['I' + (10 + i)]
    let J = ws1['J' + (10 + i)]
    A.s = {
      font: font,
      alignment: {
        vertical: 'top',
        horizontal: 'center',
        wrapText: true,
      },
      // border: border,
    }
    B.s = A.s
    C.s = {
      font: font,
      alignment: {
        vertical: 'top',
        wrapText: true,
      },
      // border: border,
    }
    D.s = C.s
    E.s = C.s
    F.s = C.s
    G.s = C.s
    H.s = C.s
    I.s = A.s
    J.s = C.s
    J.f = '=HYPERLINK("' + J.v.toString() + '","' + J.v.toString() + '")'
    J.s.font.color = '#006ce5'
    J.s.font['text-decoration'] = 'underline'
  })
  ws1['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 10 } },
    { s: { r: 4, c: 0 }, e: { r: 4, c: 10 } },
    { s: { r: 5, c: 0 }, e: { r: 5, c: 10 } },
    { s: { r: 6, c: 0 }, e: { r: 6, c: 10 } },

    { s: { r: 7, c: 0 }, e: { r: 8, c: 0 } },
    { s: { r: 7, c: 1 }, e: { r: 7, c: 4 } },
    { s: { r: 7, c: 5 }, e: { r: 7, c: 7 } },
    { s: { r: 7, c: 8 }, e: { r: 8, c: 8 } },
    { s: { r: 7, c: 9 }, e: { r: 8, c: 9 } },
    { s: { r: 7, c: 10 }, e: { r: 8, c: 10 } },
  ]
  const wb = {
    Sheets: {
      data: ws1,
    },
    SheetNames: ['data'],
    Workbook: { Views: [{ RTL: false }] },
  }

  const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([eb], { type: EXCEL_TYPE })
  saveAs(blob, name + EXCEL_EXTENSION)
}

export default DownloadExcel
