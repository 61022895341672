import React from 'react'
import { Grid, Box, FormControlLabel, MenuItem, Button, FormControl, Select, Checkbox, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ExpandMore as ExpandMoreIcon,
  // Cancel as CancelIcon,
  CheckBox as CheckBoxIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons'
import moment from 'moment'
import IntlMessages from '../@jumbo/utils/IntlMessages'
import th from 'date-fns/locale/th'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    height: '30px',
    width: '235px',
    '& .MuiInputBase-formControl': {
      height: '30px',
    },
    '& .MuiSelect-select': {
      padding: '0 10px',
      fontSize: '14px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 5px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -5px) scale(.5)',
    },
    '& fieldset': {
      borderColor: '#cb1b67 !important',
    },
    '& .MuiTextField-root': {
      margin: '0',
    },
    '& .MuiInputBase-adornedEnd': {
      border: '1px solid #cb1b67',
      borderRadius: '4px',
      '& input': {
        paddingLeft: '10px',
        fontSize: '14px',
      },
      '& .MuiSvgIcon-root': {
        color: '#cb1b67',
      },
      '&.MuiInput-underline:before': {
        display: 'none',
      },
      '&.MuiInput-underline:after': {
        display: 'none',
      },
    },
    '& .MuiFormControl-marginNormal': {
      width: '100% !important',
    },
  },
  Boxfirst: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
      padding: '0px 0px 0px 20px',
      '& .lbl': {
        width: '95px',
        justifyContent: 'end',
      },
      '& .MuiFormControl-root': {
        width: 'calc(100% - 95px)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .lbl': {
        width: '95px',
        justifyContent: 'end',
      },
      '& .MuiFormControl-root': {
        width: 'calc(100% - 95px)',
      },
    },
  },
  BoxTwo: {
    display: 'flex',
    justifyContent: 'start',
    padding: '0px 20px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
      // padding: "0px 0px 0px 20px",
      '& .lbl': {
        width: '95px',
        justifyContent: 'end',
      },
      '& .MuiFormControl-root': {
        width: 'calc(100% - 95px)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .lbl': {
        width: '95px',
        justifyContent: 'end',
      },
      '& .MuiFormControl-root': {
        width: 'calc(100% - 95px)',
      },
    },
  },
  BoxSelectLecturers: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '-35px',
    '& .box-select': {
      width: '582px',
    },
    '& .ListboxProps': {
      width: '100%',
      '& li:hover': {
        backgroundColor: '#f6eaf1',
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px 0px 0px',
      marginLeft: '0 !important',
      '& .lbl': {
        width: '95px',
        justifyContent: 'end',
      },
      '& .box-select': {
        width: 'calc(100% - 95px)',
      },
      // "& .ListboxProps": {
      //   width: "calc(100% - 95px)"
      // },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 0px',
      marginLeft: '-20px',
      '& .lbl': {
        width: '95px',
        justifyContent: 'end',
      },
      '& .box-select': {
        width: 'calc(100% - 95px)',
      },
    },
    '& .MuiInputBase-root': {
      minHeight: '30px',
      padding: '0 10px',
      '& input': {
        height: '25px',
        padding: '0 10px !important',
        fontSize: '14px',
      },
      //  flexWrap: "unset",
      '& .MuiAutocomplete-tag': {
        height: '25px',
        margin: '2.5px',
      },
      '& .MuiChip-deleteIcon': {
        color: '#cb1b67',
      },
    },
    '& .MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#cb1b67',
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: '#cb1b67',
    },
  },
  GridChecked: {
    display: 'flex',
    justifyContent: 'end',
    padding: '0px 20px 0px 0px',
    '& .MuiIconButton-label': {
      color: '#f50057',
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
      marginLeft: '95px',
    },
  },
  BoxSearch: {
    display: 'flex',
    justifyContent: 'end',
    width: '635px',
    marginBottom: '50px',
    '& .MuiButtonBase-root .Button': {
      display: 'flex',
      justifyContent: 'end',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px 20px 0px 0px',
    },
  },
  Autocomplete: {},
}))
class CustomDateFns extends DateFnsUtils {
  constructor() {
    super({ locale: th })
  }
}
const FormSearchReport = ({ state, handleChange, lecturer, search, setStatelecturers }) => {
  const classes = useStyles()
  const yearOptionStart = 1993
  const yearOptionEnd = new Date().getUTCFullYear() + 1

  const [starQuarterStatus, setstarQuarterStatus] = React.useState(false)
  const [endQuarterStatus, setendQuarterStatus] = React.useState(false)
  const QuarterStart = 1
  const QuarterEnd = 4
  let yearList = []
  let quarterList = []

  for (let i = yearOptionStart; i <= yearOptionEnd; i++) {
    yearList.push(i)
  }
  for (let i = QuarterStart; i <= QuarterEnd; i++) {
    quarterList.push(i)
  }
  const GetYearOption = () => {
    return yearList.map(e => {
      return (
        <MenuItem key={e} value={e}>
          {e}
        </MenuItem>
      )
    })
  }
  const GetQuarterOption = () => {
    return quarterList.map(e => {
      return (
        <MenuItem key={e} value={e}>
          {e}
        </MenuItem>
      )
    })
  }
  return (
    <>
      <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
        <Box className={classes.Boxfirst}>
          <Box
            className="lbl"
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0 10px',
            }}>
            ปี :
          </Box>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={state.startYear}
              onChange={handleChange}
              IconComponent={props => {
                const rotate = props.className.toString().includes('iconOpen')
                return !rotate ? (
                  <ExpandMoreIcon style={{ color: '#cb1b67' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#cb1b67' }} />
                )
              }}
              inputProps={{
                name: 'startYear',
                id: 'outlined-startYear-native-simple',
              }}>
              {GetYearOption()}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <MuiPickersUtilsProvider utils={CustomDateFns}>
        <Grid item xs={12} sm={6} style={{ padding: '0 0 0 20px', marginBottom: '15px' }}>
          <Box className={classes.BoxTwo}>
            <Box
              className="lbl"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 10px',
              }}>
              วันที :
            </Box>
            <FormControl variant="outlined" className={classes.formControl}>
              {/* <Select
              value={state.starQuarter}
              onChange={handleChange}
              IconComponent={props => {
                const rotate = props.className.toString().includes('iconOpen')
                return !rotate ? (
                  <ExpandMoreIcon style={{ color: '#cb1b67' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#cb1b67' }} />
                )
              }}
              inputProps={{
                name: 'starQuarter',
                id: 'outlined-starQuarter-native-simple',
              }}>
              {GetQuarterOption()}
            </Select> */}
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              <KeyboardDatePicker
                disableToolbar
                name="starQuarter"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="starQuarter"
                maxDate={'12/31/' + state.startYear}
                minDate={'01/01/' + state.startYear}
                value={state.starQuarter}
                onChange={d => {
                  handleChange({ target: { name: 'starQuarter', value: d } })
                }}
                onClick={() => setstarQuarterStatus(true)}
                onClose={() => setstarQuarterStatus(false)}
                open={starQuarterStatus}
                InputProps={{ readOnly: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              {/* </MuiPickersUtilsProvider> */}
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box className={classes.Boxfirst}>
            <Box
              className="lbl"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 10px',
              }}>
              ถึงปี :
            </Box>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                value={state.endYear}
                onChange={handleChange}
                IconComponent={props => {
                  const rotate = props.className.toString().includes('iconOpen')
                  return !rotate ? (
                    <ExpandMoreIcon style={{ color: '#cb1b67' }} />
                  ) : (
                    <ExpandLessIcon style={{ color: '#cb1b67' }} />
                  )
                }}
                inputProps={{
                  name: 'endYear',
                  id: 'outlined-endYear-native-simple',
                }}>
                {GetYearOption()}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 0 0 20px', marginBottom: '15px' }}>
          <Box className={classes.BoxTwo}>
            <Box
              className="lbl"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 10px',
              }}>
              วันที :
            </Box>
            <FormControl variant="outlined" className={classes.formControl}>
              {/* <Select
              value={state.endQuarter}
              onChange={handleChange}
              IconComponent={props => {
                const rotate = props.className.toString().includes('iconOpen')
                return !rotate ? (
                  <ExpandMoreIcon style={{ color: '#cb1b67' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#cb1b67' }} />
                )
              }}
              inputProps={{
                name: 'endQuarter',
                id: 'outlined-endQuarter-native-simple',
              }}>
              {GetQuarterOption()}
            </Select> */}
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              <KeyboardDatePicker
                disableToolbar
                name="endQuarter"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="endQuarter"
                maxDate={'12/31/' + state.endYear}
                minDate={'01/01/' + state.endYear}
                value={state.endQuarter}
                onChange={d => {
                  handleChange({ target: { name: 'endQuarter', value: d } })
                }}
                onClick={() => setendQuarterStatus(true)}
                onClose={() => setendQuarterStatus(false)}
                open={endQuarterStatus}
                InputProps={{ readOnly: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              {/* </MuiPickersUtilsProvider> */}
            </FormControl>
          </Box>
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid item xs={12} sm={12} style={{ padding: '0 0 0 20px', marginBottom: '15px' }}>
        <Box className={classes.BoxSelectLecturers}>
          <Box
            className="lbl"
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0 10px',
            }}>
            ชื่ออาจารย์ :
          </Box>
          <Box className="box-select">
            <Autocomplete
              multiple
              variant="outlined"
              id="checkboxes-tags-demo"
              options={lecturer}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              renderOption={(option, { selected }) => (
                // <React.Fragment >
                <Box style={{ display: 'flex', width: '100%' }}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />

                  <Box>
                    <Box style={{ fontSize: '14px', color: '#000' }}>{option.name}</Box>
                    <Box style={{ padding: '0 0 0 0px', color: '#aaa', fontSize: '12px' }}>{option.scopusID} </Box>
                  </Box>
                </Box>
                // </React.Fragment>
              )}
              // style={{ width: 500 }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  // label="Checkboxes"
                  placeholder="ชื่ออาจารย์"
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#cb1b67' }} />}
              onChange={(e, v) => {
                state.lecturers = v
                setStatelecturers({ ...state })
              }}
            />
            {/* <CustomizedHook
              options={lecturer}
              id="select-lecturers"
              onChange={e => {
                state.lecturers = e
                setStatelecturers({ ...state })
              }}
            /> */}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} style={{ padding: '0 0 0 20px', marginBottom: '15px' }}>
        <Grid item xs={12} sm={6} className={classes.GridChecked}>
          <FormControlLabel
            style={{ width: '240px' }}
            control={<Checkbox checked={state.checkedScopusId} name="checkedScopusId" onChange={handleChange} />}
            label="ค้นหาจาก Scopus ID"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} style={{ padding: '0 0 0 20px', display: 'flex', justifyContent: 'center' }}>
        <Box className={classes.BoxSearch}>
          <Box className="Button">
            <Button variant="contained" color="primary" onClick={search}>
              <IntlMessages id="appModule.search" />
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default FormSearchReport
