import React, { useEffect, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
import { Grid } from '@material-ui/core'
import { fetchStart, fetchSuccess } from '../../../redux/actions'
import * as fn from '../../../services/default.service'
import { getHttp, postHttp } from '../../../services/http'
import FormSearchReport from '../../../components/formSearchReport'
import Report from './report'
import _ from 'lodash'
import moment from 'moment'
const Index = () => {
  const dispatch = useDispatch()
  const { setAlertDialog, setAlert } = useContext(AppContext)
  const tableRef = useRef(null)
  const [state, setState] = React.useState({
    startYear: new Date().getUTCFullYear(),
    endYear: new Date().getUTCFullYear(),
    starQuarter: new Date(),
    endQuarter: new Date(),
    checkedScopusId: true,
  })
  // let [metadata, setmetadata] = React.useState([])

  const [reportData, setReport] = React.useState({
    isView: false,
    data: {},
  })
  const [lecturer, setLecturer] = React.useState([])

  const getLecturer = async isCurrent => {
    dispatch(fetchStart())
    let user = await getHttp('/report/Lecturers', dispatch)
    if (user.data.status) {
      let tbLecturers = user.data.tbLecturers
      let lecturersList = []
      _.filter([...tbLecturers], (e, i) => {
        lecturersList.push({
          id: e.id,
          name: e.fullNameEng,
          scopusID: e.scopusID,
          firstNameEng: e.firstNameEng,
          lastNameEng: e.lastNameEng,
          firstInitial: fn.IsNullOrEmpty(e.firstInitial) ? '' : e.firstInitial,
        })
      })
      if (isCurrent) {
        setLecturer(lecturersList)
      }
    }
    dispatch(fetchSuccess())
  }

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    if (name === 'startYear') {
      let starQuarter = new Date(state.starQuarter.setFullYear(value))
      let endQuarter =
        value > state.endYear
          ? new Date(state.endQuarter.setFullYear(value))
          : new Date(state.endQuarter.setFullYear(state.endYear))
      setState({
        ...state,
        [name]: value,
        starQuarter: starQuarter,
        endYear: value > state.endYear ? value : state.endYear,
        endQuarter: starQuarter < endQuarter ? endQuarter : starQuarter,
      })
    } else if (name === 'endYear') {
      setState({
        ...state,
        [name]: value,
        startYear: value < state.startYear ? value : state.startYear,
        starQuarter:
          value < state.startYear
            ? state.endQuarter
            : state.endQuarter < state.starQuarter
            ? state.endQuarter
            : state.starQuarter,
        endQuarter: new Date(state.endQuarter.setFullYear(value)),
      })
    } else {
      setState({
        ...state,
        [name]: value,
      })
    }
  }

  const search = async () => {
    setReport({
      isView: false,
      data: {},
    })
    let parameter = {
      startYear: state.startYear,
      endYear: state.endYear,
      starQuarter: moment(state.starQuarter).format('yyyy-MM-DD'),
      endQuarter: moment(state.endQuarter).format('yyyy-MM-DD'),
      isScopusId: state.checkedScopusId,
    }
    let query = []
    let dataReport = []
    setReport({ isView: false, data: { data: [] } })
    // let setmetadata
    // setmetadata([])
    let metadata = []
    console.log(time)
    let startDate = ''
    let endDate = ''
    let dateNow = ''
    let request = true
    let percentage = 0
    const getcitationReport = async () => {
      parameter.query = query
      dispatch(fetchStart(percentage + '/100'))
      let _citationReport = await postHttp('/report/citationReport', parameter)
      if (_citationReport.status) {
        if (_citationReport.data.status) {
          const _metadata = _citationReport.data.payload.data
          let index = 1
          _.map([..._metadata], (meta, meta_index) => {
            if (meta.REF.length > 0) {
              // _.map([...meta.REF], (ref, ref_index) => {
              //   dataReport.push({
              //     index: index,
              //     'Publication:year': ref_index === 0 ? meta.year : '',
              //     'Publication:title': ref_index === 0 ? meta.title : '',
              //     'Publication:author': ref_index === 0 ? meta.author : '',
              //     'Publication:Journal': ref_index === 0 ? meta.Journal : '',
              //     'CitedBy:title': ref.title,
              //     'CitedBy:Journal': ref.Journal,
              //     'CitedBy:author': ref.author,
              //     'CitedBy:international': 'scopus (' + ref.year + ')',
              //     'CitedBy:link': ref.link,
              //   })
              //   index += 1
              // })
              // let data = metadata
              metadata.push(meta)
              // setmetadata(data)
            }
          })
          startDate = _citationReport.data.payload.startDate
          endDate = _citationReport.data.payload.endDate
          dateNow = _citationReport.data.payload.dateNow
          // setReport({
          //   // isView: true,
          //   data: {
          //     data: dataReport,
          //     endDate: _citationReport.data.payload.endDate,
          //     startDate: _citationReport.data.payload.startDate,
          //     total: _citationReport.data.payload.total,
          //     totalresearcher: _citationReport.data.payload.totalresearcher,
          //     avgSCOPUS: _citationReport.data.payload.avgSCOPUS,
          //   },
          // })
          // tableRef.current.dataManager.changePageSize(dataReport.length || 1)
          // return { code: 200 }
        } else {
          setAlertDialog({
            open: true,
            type: 'alert',
            msg: 'ค้นข้อมูล ไม่สำเร็จ',
            Close: even => {
              even()
            },
          })
          request = false
        }
        // return { code: 400 }
        // request = false
      } else {
        setAlertDialog({
          open: true,
          type: 'alert',
          msg: 'ค้นข้อมูล ไม่สำเร็จ',
          Close: even => {
            even()
          },
        })
        // return { code: 400 }
        request = false
      }
      // return { code: 200 }
    }

    let time = await (async () => {
      setTimeout(async () => {
        if (fn.IsNullOrEmpty(state.lecturers)) {
          if (!state.checkedScopusId) {
            // _.map([...lecturer], (e, i) => {
            //   query.push(e.lastNameEng + ' ' + e.firstInitial)
            // })
            let setpage = 1
            let pagesize = 5
            for (let _index = 0; _index < lecturer.length; _index++) {
              query.push(lecturer[_index].lastNameEng + ' ' + lecturer[_index].firstInitial)
              if (_index === setpage * pagesize || _index === lecturer.length - 1) {
                if (request) {
                  const data = await getcitationReport()
                  console.log(data)
                }
                query = []
                setpage = setpage + 1
                percentage =
                  percentage + (((lecturer.length / pagesize) * 100) / lecturer.length) * (lecturer.length / 100) * 5
              }
            }
          } else {
            // _.map([...lecturer], (e, i) => {
            //   if (!fn.IsNullOrEmpty(e.scopusID)) {
            //     query.push(e.scopusID)
            //   }
            // })
            let setpage = 1
            let pagesize = 5
            for (let _index = 0; _index < lecturer.length; _index++) {
              if (!fn.IsNullOrEmpty(lecturer[_index].scopusID)) {
                query.push(lecturer[_index].scopusID)
              }
              if (_index === setpage * pagesize || _index === lecturer.length - 1) {
                if (request) {
                  const data = await getcitationReport()
                  console.log(data)
                }
                query = []
                setpage = setpage + 1
              }
            }
          }
        } else {
          if (!state.checkedScopusId) {
            _.map([...state.lecturers], (e, i) => {
              query.push(e.lastNameEng + ' ' + e.firstInitial)
            })
            if (request) {
              const data = await getcitationReport()
              console.log(data)
            }
          } else {
            // _.map([...state.lecturers], (e, i) => {
            //   query.push(e.scopusID)
            // })
            // if (request) {
            //   const data = await getcitationReport()
            //   console.log(data)
            // }
            let setpage = 1
            let pagesize = 5
            for (let _index = 0; _index < state.lecturers.length; _index++) {
              if (!fn.IsNullOrEmpty(state.lecturers[_index].scopusID)) {
                query.push(state.lecturers[_index].scopusID)
              }
              if (_index === setpage * pagesize || _index === state.lecturers.length - 1) {
                if (request) {
                  const data = await getcitationReport()
                  console.log(data)
                }
                query = []
                setpage = setpage + 1
              }
            }
          }
        }
        const sortBy = (arr, keys) => {
          return arr.sort(function sort(i1, i2, sKeys = keys) {
            // Get order and key based on structure
            const compareKey = sKeys[0].key ? sKeys[0].key : sKeys[0]
            const order = sKeys[0].order || 'ASC' // ASC || DESC
            // Calculate compare value and modify based on order
            let compareValue = i1[compareKey].toString().localeCompare(i2[compareKey].toString())
            compareValue = order.toUpperCase() === 'DESC' ? compareValue * -1 : compareValue
            // See if the next key needs to be considered
            const checkNextKey = compareValue === 0 && sKeys.length !== 1
            // Return compare value
            return checkNextKey ? sort(i1, i2, sKeys.slice(1)) : compareValue
          })
        }
        let index = 0
        let payloaddata = sortBy(dataReport, [
          { key: 'Publication:year', order: 'desc' },
          { key: 'Publication:title', order: 'asc' },
        ])
        let length = 0

        let indexinfo = 1
        let metadatanotnull = metadata.filter(e => {
          if (!fn.IsNullOrEmpty(e.REF)) {
            if (e.REF.length > 0) {
              return e
            }
          }
        })
        let identifier_dup = []
        metadatanotnull = metadatanotnull.filter(e => {
          if (!identifier_dup.includes(e.identifier)) {
            identifier_dup.push(e.identifier)
            return e
          }
        })
        metadatanotnull = sortBy(metadatanotnull, [
          { key: 'year', order: 'desc' },
          { key: 'title', order: 'asc' },
        ])

        for (let i = 0; i < metadatanotnull.length; i++) {
          if (metadatanotnull[i].REF.length > 0) {
            for (let j = 0; j < metadatanotnull[i].REF.length; j++) {
              let _checkDoi = await postHttp('/report/checkDoi', {
                doi: metadatanotnull[i]['doi'],
                urlapi: metadatanotnull[i].REF[j]['urlapi'],
              })
              if (_checkDoi.status) {
                metadatanotnull[i].REF[j]['isDoi'] = _checkDoi.data.status
              } else {
                metadatanotnull[i].REF[j]['isDoi'] = false
              }
            }
          }
        }

        let REF_DATA = []
        _.map([...metadatanotnull], (meta, meta_index) => {
          if (meta.REF.length > 0) {
            // index = index + meta.REF.length
            _.map([...meta.REF], (ref, ref_index) => {
              if (ref.isDoi) {
                if (!REF_DATA.includes(ref.identifier)) {
                  REF_DATA.push(ref.identifier)
                  index = index + 1
                }
                dataReport.push({
                  index: indexinfo,
                  'Publication:year': ref_index === 0 ? meta.year : '',
                  'Publication:title': ref_index === 0 ? meta.title : '',
                  'Publication:author': ref_index === 0 ? meta.author : '',
                  'Publication:Journal': ref_index === 0 ? meta.Journal : '',
                  'CitedBy:title': ref.title,
                  'CitedBy:Journal': ref.Journal,
                  'CitedBy:author': ref.author,
                  'CitedBy:international': 'scopus (' + ref.year + ')',
                  'CitedBy:link': ref.link,
                  econJournal: ref.econJournal,
                })
                indexinfo += 1
              }
            })
            // metadata.push(meta)
            // setmetadata(metadata)
          }
        })
        let lecturers_count = 0
        if (fn.IsNullOrEmpty(state.lecturers)) {
          lecturer.map(e => {
            let name = (e.lastNameEng + ' ' + e.firstInitial).toLowerCase()
            let _isempty = true
            payloaddata.map(j => {
              if (j['Publication:author'].toLowerCase().includes(name)) {
                _isempty = false
              }
            })
            if (!_isempty) {
              lecturers_count = lecturers_count + 1
            }
          })
        } else {
          state.lecturers.map(e => {
            let name = (e.lastNameEng + ' ' + e.firstInitial).toLowerCase()
            let _isempty = true
            payloaddata.map(j => {
              if (j['Publication:author'].toLowerCase().includes(name)) {
                _isempty = false
              }
            })
            if (!_isempty) {
              lecturers_count = lecturers_count + 1
            }
          })
        }
        setReport({
          isView: true,
          data: {
            data: payloaddata,
            endDate: endDate,
            startDate: startDate,
            total: index === 0 ? 0 : indexinfo - 1,
            totalresearcher: lecturers_count,
            avgSCOPUS: index == 0 ? '0.00' : ((indexinfo - 1) / lecturers_count).toFixed(2),
            dateNow: dateNow,
          },
        })
        tableRef.current.dataManager.changePageSize(payloaddata.length || 1)
        setAlert({ open: true, type: 'success', msg: 'ค้นข้อมูลสำเร็จ' })
        dispatch(fetchSuccess())
      }, 200)
    })()
  }

  const setStatelecturers = state => {
    setState({ ...state })
  }

  useEffect(() => {
    let isCurrent = true
    getLecturer(isCurrent)
    return () => {
      isCurrent = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <PageContainer heading={<IntlMessages id="pages.citationReport" />}>
      <GridContainer>
        <FormSearchReport
          state={state}
          handleChange={handleChange}
          lecturer={lecturer}
          search={search}
          setStatelecturers={setStatelecturers}
        />
        {reportData.isView ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              padding: '0 20px 0 20px',
            }}>
            <Report data={reportData.data} tableRef={tableRef} />
          </Grid>
        ) : null}
      </GridContainer>
    </PageContainer>
  )
}

export default Index
