import XLSX from 'xlsx-color'
import { saveAs } from 'file-saver'
import moment from 'moment'
import _ from 'lodash'
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

const DownloadExcel = (data, name, fetchStart, fetchSuccess, alert) => {
  try {
    fetchStart()
    let item = []
    _.map([...data.data], e => {
      item.push({
        index: e.index,
        title: e['title'],
        author: e['author'],
        year: e['year'],
        publicationName: e['publicationName'],
        Link: e['Link'],
        Journal: e['Journal'],
        econJournal: e['econJournal'],
      })
    })
    let ws1 = XLSX.utils.sheet_add_json(XLSX.utils.book_new().Sheets.sheet1, item, {
      skipHeader: true,
      origin: 'A10',
    })

    ws1['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 7 } },
      { s: { r: 5, c: 0 }, e: { r: 5, c: 7 } },
      { s: { r: 6, c: 0 }, e: { r: 6, c: 7 } },
      { s: { r: 7, c: 0 }, e: { r: 7, c: 7 } },
    ]
    ws1['!cols'] = [
      { width: 10 },
      { width: 20 },
      { width: 20 },
      { width: 10 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ]
    const font = {
      name: 'TH SarabunPSK',
    }
    ws1['A1'] = {
      t: 's',
      v: 'รายงานจำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS​',
      s: {
        font: font,
        alignment: {
          vertical: 'center',
          horizontal: 'center',
        },
      },
    }
    ws1['A2'] = {
      t: 's',
      v: 'วันที่ ' + data.startDate + ' ถึงวันที่ ' + data.endDate,
      s: {
        font: font,
        alignment: {
          vertical: 'center',
          horizontal: 'center',
        },
      },
    }
    ws1['A3'] = {
      t: 's',
      v: 'จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
      s: {
        font: font,
        alignment: {
          vertical: 'center',
          horizontal: 'right',
        },
      },
    }
    ws1['A4'] = {
      t: 's',
      v: 'จำนวนบทความทั้งหมด ' + data.total + ' บทความ',
      s: {
        font: font,
        alignment: {
          vertical: 'center',
          horizontal: 'right',
        },
      },
    }
    ws1['A5'] = {
      t: 's',
      v: 'จำนวนบทความที่อยู่ในวารสาร Q1 จำนวน ' + data.totalq1 + ' บทความ',
      s: {
        font: font,
        alignment: {
          vertical: 'center',
          horizontal: 'right',
        },
      },
    }
    ws1['A6'] = {
      t: 's',
      v: 'จำนวนอาจารย์และนักวิจัย ' + data.totalresearcher + ' คน',
      s: {
        font: font,
        alignment: {
          vertical: 'center',
          horizontal: 'right',
        },
      },
    }
    ws1['A7'] = {
      t: 's',
      v: 'จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ใน SCOPUS ' + data.avgSCOPUS,
      s: {
        font: font,
        alignment: {
          vertical: 'center',
          horizontal: 'right',
        },
      },
    }
    ws1['A8'] = {
      t: 's',
      v: 'ข้อมูล ณ วันที่ ' + data.dateNow,
      s: {
        font: font,
        alignment: {
          vertical: 'center',
          horizontal: 'right',
        },
      },
    }

    // const hdborder = {
    //   right: {
    //     style: 'thin',
    //     color: 'f6eaf1',
    //   },
    //   left: {
    //     style: 'thin',
    //     color: 'f6eaf1',
    //   },
    //   top: {
    //     style: 'thin',
    //     color: 'f6eaf1',
    //   },
    //   bottom: {
    //     style: 'thin',
    //     color: 'f6eaf1',
    //   },
    // }
    const hds = {
      font: font,
      alignment: {
        vertical: 'center',
        horizontal: 'center',
      },
      fill: {
        fgColor: { rgb: 'e5e5e5' },
      },
      // border: hdborder,
    }
    ws1['A9'] = {
      t: 's',
      v: 'ลำดับที่',
      s: hds,
    }
    ws1['B9'] = {
      t: 's',
      v: 'ชื่อผลงานวิจัย',
      s: hds,
    }
    ws1['C9'] = {
      t: 's',
      v: 'ชื่อผู้เขียนหลัก/ร่วมเขียน',
      s: hds,
    }
    ws1['D9'] = {
      t: 's',
      v: 'ปี',
      s: hds,
    }
    ws1['E9'] = {
      t: 's',
      v: 'ชื่อวารสารที่ตีพิมพ์',
      s: hds,
    }
    ws1['F9'] = {
      t: 's',
      v: 'Link',
      s: hds,
    }
    ws1['G9'] = {
      t: 's',
      v: 'Journal Ranking by SJR',
      s: hds,
    }
    ws1['H9'] = {
      t: 's',
      v: 'Q economics',
      s: hds,
    }

    _.map([...item], (e, i) => {
      // const border = {
      //   right: {
      //     style: 'thin',
      //     color: 'f6eaf1',
      //   },
      //   left: {
      //     style: 'thin',
      //     color: 'f6eaf1',
      //   },
      //   top: {
      //     style: 'thin',
      //     color: 'f6eaf1',
      //   },
      //   bottom: {
      //     style: 'thin',
      //     color: 'f6eaf1',
      //   },
      // }
      let A = ws1['A' + (10 + i)]
      let B = ws1['B' + (10 + i)]
      let C = ws1['C' + (10 + i)]
      let D = ws1['D' + (10 + i)]
      let E = ws1['E' + (10 + i)]
      let F = ws1['F' + (10 + i)]
      let G = ws1['G' + (10 + i)]
      let H = ws1['H' + (10 + i)]
      A.s = {
        font: font,
        alignment: {
          vertical: 'top',
          horizontal: 'center',
          wrapText: true,
        },
        // border: border,
      }
      B.s = {
        font: font,
        alignment: {
          vertical: 'top',
          wrapText: true,
        },
        // border: border,
      }
      C.s = A.s
      D.s = C.s
      E.s = C.s
      F.s = C.s
      F.f = '=HYPERLINK("' + F.v.toString() + '","' + F.v.toString() + '")'
      F.s.font.color = '#006ce5'
      F.s.font['text-decoration'] = 'underline'
      G.s = A.s
      H.s = A.s

      // H.s = C.s
      // I.s = A.s
      // J.s = C.s
      // J.f = '=HYPERLINK("' + J.v.toString() + '","' + J.v.toString() + '")'
      // J.s.font.color = "#006ce5"
      // J.s.font["text-decoration"] = "underline"
    })
    const wb = {
      Sheets: {
        data: ws1,
      },
      SheetNames: ['data'],
      Workbook: { Views: [{ RTL: false }] },
    }

    const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([eb], { type: EXCEL_TYPE })
    saveAs(blob, name + EXCEL_EXTENSION)
    fetchSuccess()
  } catch (err) {
    fetchSuccess()
    alert('เกิดข้อผิดพลาด')
  }
}

export default DownloadExcel
