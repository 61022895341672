import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import CallAddFontThNormal from '../thsarabunnew-webfont'
import _ from 'lodash'
import moment from 'moment'
jsPDF.API.events.push(['addFonts', CallAddFontThNormal])

export const DownloadPDFP = (data, name) => {
  const doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4', compressPdf: true })
  doc.setFont('thsarabunnew-webfont', 'normal')
  doc.setFont('thsarabunnew-webfont', 'bold')

  autoTable(doc, {
    head: [
      [
        {
          content: 'รายงานจำนวน Citations ต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
          styles: { halign: 'center', valign: 'middle' },
        },
      ],
      [
        {
          content: 'วันที่ ' + data.startDate + ' ถึงวันที่ ' + data.endDate,
          styles: { halign: 'center', valign: 'middle' },
        },
      ],
    ],
    headerStyles: {
      font: 'thsarabunnew-webfont',
      fontStyle: 'bold',
      fillColor: '#FFF',

      fontSize: 10,
      textColor: '#000',
    },
  })
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 5,
    head: [
      [
        {
          content: 'จำนวน Citations ต่ออาจารย์ และนักวิจัย ที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
          styles: { halign: 'right', valign: 'middle' },
        },
      ],
      [{ content: 'จำนวน Citations ทั้งหมด ' + data.total + ' บทความ', styles: { halign: 'right', valign: 'middle' } }],
      [
        {
          content: 'จำนวนอาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS ' + data.totalresearcher + ' คน',
          styles: { halign: 'right', valign: 'middle' },
        },
      ],
      [
        {
          content: 'จำนวนการอ้างอิงผลงานวิจัย (Citations) ในฐานข้อมูล Scopus เท่ากับ ' + data.avgSCOPUS,
          styles: { halign: 'right', valign: 'middle' },
        },
      ],
      [
        {
          content: 'ข้อมูล ณ วันที่ ' + data.dateNow,
          styles: { halign: 'right', valign: 'middle' },
        },
      ],
    ],
    headerStyles: {
      font: 'thsarabunnew-webfont',
      fontStyle: 'bold',
      fillColor: '#FFF',
      lineColor: '#f6eaf1',
      lineWidth: 0.3,
      fontSize: 10,
      textColor: '#000',
    },
  })
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY,
    head: [
      [
        { content: 'ลำดับ', colSpan: 1, rowSpan: 2, styles: { halign: 'center', valign: 'middle', cellWidth: 15 } },
        { content: 'Publication', colSpan: 4, rowSpan: 1, styles: { halign: 'center', cellWidth: 'auto' } },
        { content: 'Cited By', colSpan: 3, rowSpan: 1, styles: { halign: 'center', cellWidth: 'auto' } },
        { content: 'ฐานข้อมูลสากล', colSpan: 1, rowSpan: 2, styles: { halign: 'center', valign: 'middle', cellWidth: 27 } },
        { content: 'แหล่งอ้างอิง', colSpan: 1, rowSpan: 2, styles: { halign: 'center', valign: 'middle', cellWidth: 25 } },
        { content: 'Q economics', colSpan: 1, rowSpan: 2, styles: { halign: 'center', valign: 'middle', cellWidth: 25 } },
      ],
      [
        { content: 'Year', colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 12 } },
        { content: 'Title', colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 'auto' } },
        { content: 'Authors', colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 'auto' } },
        { content: 'Journal', colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 20 } },

        { content: 'Title', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'Journal', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'Authors', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
      ],
    ],
    body: (() => {
      let l = []

      _.map([...data.data], e => {
        l.push([
          { content: e.index, colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },

          { content: e['Publication:year'], colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
          { content: e['Publication:title'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['Publication:author'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['Publication:Journal'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },

          { content: e['CitedBy:title'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['CitedBy:Journal'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['CitedBy:author'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['CitedBy:international'], colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
          { content: e['CitedBy:link'], colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
          { content: e['econJournal'], colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        ])
      })
      return l
    })(),

    headerStyles: {
      font: 'thsarabunnew-webfont',
      fontStyle: 'bold',
      fillColor: '#e5e5e5',
      lineColor: '#f6eaf1',
      lineWidth: 0.3,
      fontSize: 10,
      textColor: '#000',
    },
    bodyStyles: {
      font: 'thsarabunnew-webfont',
      fontStyle: 'normal',
      lineColor: '#f6eaf1',
      lineWidth: 0.3,
      fontSize: 10,
      textColor: '#000',
    },
    tableWidth: 182,
  })

  doc.save(name + '.pdf')
}
export const DownloadPDFL = (data, name) => {
  const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4', compressPdf: true })
  doc.setFont('thsarabunnew-webfont', 'normal')
  doc.setFont('thsarabunnew-webfont', 'bold')

  autoTable(doc, {
    head: [
      [
        {
          content: 'รายงานจำนวน Citations ต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
          styles: { halign: 'center', valign: 'middle' },
        },
      ],
      [
        {
          content: 'วันที่ ' + data.startDate + ' ถึงวันที่ ' + data.endDate,
          styles: { halign: 'center', valign: 'middle' },
        },
      ],
    ],
    headerStyles: {
      font: 'thsarabunnew-webfont',
      fontStyle: 'bold',
      fillColor: '#FFF',

      fontSize: 10,
      textColor: '#000',
    },
  })
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 5,
    head: [
      [
        {
          content: 'จำนวน Citations ต่ออาจารย์ และนักวิจัย ที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
          styles: { halign: 'right', valign: 'middle' },
        },
      ],
      [{ content: 'จำนวน Citations ทั้งหมด ' + data.total + ' บทความ', styles: { halign: 'right', valign: 'middle' } }],
      [
        {
          content: 'จำนวนอาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS ' + data.totalresearcher + ' คน',
          styles: { halign: 'right', valign: 'middle' },
        },
      ],
      [
        {
          content: 'จำนวนการอ้างอิงผลงานวิจัย (Citations) ในฐานข้อมูล Scopus เท่ากับ ' + data.avgSCOPUS,
          styles: { halign: 'right', valign: 'middle' },
        },
      ],
      [
        {
          content: 'ข้อมูล ณ วันที่ ' + data.dateNow,
          styles: { halign: 'right', valign: 'middle' },
        },
      ],
    ],
    headerStyles: {
      font: 'thsarabunnew-webfont',
      fontStyle: 'bold',
      fillColor: '#FFF',
      lineColor: '#f6eaf1',
      lineWidth: 0.3,
      fontSize: 10,
      textColor: '#000',
    },
  })
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY,
    head: [
      [
        { content: 'ลำดับ', colSpan: 1, rowSpan: 2, styles: { halign: 'center', valign: 'middle' } },
        { content: 'Publication', colSpan: 4, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'Cited By', colSpan: 3, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'ฐานข้อมูลสากล', colSpan: 1, rowSpan: 2, styles: { halign: 'center', valign: 'middle' } },
        { content: 'แหล่งอ้างอิง', colSpan: 1, rowSpan: 2, styles: { halign: 'center', valign: 'middle' } },
        { content: 'Q economics', colSpan: 1, rowSpan: 2, styles: { halign: 'center', valign: 'middle' } },
      ],
      [
        { content: 'Year', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'Title', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'Authors', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'Journal', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },

        { content: 'Title', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'Journal', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        { content: 'Authors', colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
      ],
    ],
    body: (() => {
      let l = []

      _.map([...data.data], e => {
        l.push([
          { content: e.index, colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },

          { content: e['Publication:year'], colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
          { content: e['Publication:title'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['Publication:author'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['Publication:Journal'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },

          { content: e['CitedBy:title'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['CitedBy:Journal'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['CitedBy:author'], colSpan: 1, rowSpan: 1, styles: { halign: 'left' } },
          { content: e['CitedBy:international'], colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
          { content: e['CitedBy:link'], colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
          { content: e['econJournal'], colSpan: 1, rowSpan: 1, styles: { halign: 'center' } },
        ])
      })
      return l
    })(),

    headerStyles: {
      font: 'thsarabunnew-webfont',
      fontStyle: 'bold',
      fillColor: '#e5e5e5',
      lineColor: '#f6eaf1',
      lineWidth: 0.3,
      fontSize: 10,
      textColor: '#000',
    },
    bodyStyles: {
      font: 'thsarabunnew-webfont',
      fontStyle: 'normal',
      lineColor: '#f6eaf1',
      lineWidth: 0.3,
      fontSize: 10,
      textColor: '#000',
    },
    tableWidth: 269,
    columnStyles: {
      0: { valign: 'top', columnWidth: 14 },
      1: {
        columnWidth: 20,
        halign: 'center',
      },
      2: {
        columnWidth: 35,
        halign: 'center',
      },
      3: {
        columnWidth: 25,
        halign: 'center',
      },
      4: {
        columnWidth: 25,
        halign: 'center',
      },
      5: {
        columnWidth: 25,
        halign: 'center',
      },
      6: {
        columnWidth: 25,
        halign: 'center',
      },
      7: {
        columnWidth: 25,
        halign: 'center',
      },
      8: {
        columnWidth: 25,
        halign: 'center',
      },
      9: {
        columnWidth: 25,
        halign: 'center',
      },
      10: {
        columnWidth: 25,
        halign: 'center',
      },
    },
  })

  doc.save(name + '.pdf')
}
export default DownloadPDFP
