import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import CallAddFontThNormal from '../thsarabunnew-webfont'
import moment from 'moment'
import _ from 'lodash'
jsPDF.API.events.push(['addFonts', CallAddFontThNormal])

const DownloadPDFP = (data, name, fetchStart, fetchSuccess, alert) => {
  try {
    fetchStart()
    const doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4', compressPdf: true })
    doc.setFont('thsarabunnew-webfont', 'normal')
    doc.setFont('thsarabunnew-webfont', 'bold')

    autoTable(doc, {
      head: [
        [
          {
            content: 'รายงานจำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
        [
          {
            content: 'วันที่ ' + data.startDate + ' ถึงวันที่ ' + data.endDate,
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
      ],
      headerStyles: {
        font: 'thsarabunnew-webfont',
        fontStyle: 'bold',
        fillColor: '#FFF',

        fontSize: 10,
        textColor: '#000',
      },
    })
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [
        [
          {
            content: 'จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'จำนวนบทความทั้งหมด ' + data.total + ' บทความ',
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'จำนวนบทความที่อยู่ในวารสาร Q1 จำนวน ' + data.totalq1 + ' บทความ',
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'จำนวนอาจารย์และนักวิจัย ' + data.totalresearcher + ' คน',
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ใน SCOPUS ' + data.avgSCOPUS,
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'ข้อมูล ณ วันที่ ' + data.dateNow,
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
      ],
      headerStyles: {
        font: 'thsarabunnew-webfont',
        fontStyle: 'bold',
        fillColor: '#FFF',
        lineColor: '#f6eaf1',
        lineWidth: 0.3,
        fontSize: 10,
        textColor: '#000',
      },
    })
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY,
      head: [
        [
          { content: 'ลำดับที่', colSpan: 1, rowSpan: 1, styles: { halign: 'center', valign: 'middle', cellWidth: 15 } },
          {
            content: 'ชื่อผลงานวิจัย',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 'auto' },
          },
          {
            content: 'ชื่อผู้เขียนหลัก/ร่วมเขียน',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 25 },
          },
          { content: 'ปี', colSpan: 1, rowSpan: 1, styles: { halign: 'center', valign: 'middle', cellWidth: 15 } },
          {
            content: 'ชื่อวารสารที่ตีพิมพ์',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 25 },
          },
          { content: 'Link', colSpan: 1, rowSpan: 1, styles: { halign: 'center', valign: 'middle', cellWidth: 25 } },
          {
            content: 'Journal Ranking by SJR',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 25 },
          },
        ],
      ],
      body: (() => {
        let l = []

        _.map([...data.data], e => {
          l.push([
            { content: e.index, colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 15 } },
            { content: e['title'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 'auto' } },
            { content: e['author'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 25 } },
            { content: e['year'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 15 } },
            { content: e['publicationName'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 25 } },
            { content: e['Link'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 25 } },
            { content: e['Journal'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 25 } },
          ])
        })
        return l
      })(),

      headerStyles: {
        font: 'thsarabunnew-webfont',
        fontStyle: 'bold',
        fillColor: '#e5e5e5',
        lineColor: '#f6eaf1',
        lineWidth: 0.3,
        fontSize: 10,
        textColor: '#000',
      },
      bodyStyles: {
        font: 'thsarabunnew-webfont',
        fontStyle: 'normal',
        lineColor: '#f6eaf1',
        lineWidth: 0.3,
        fontSize: 10,
        textColor: '#000',
      },
      tableWidth: 182,
      columnStyles: {
        0: { cellWidth: 15 },
        1: { cellWidth: 25 },
        2: { cellWidth: 25 },
        3: { cellWidth: 15 },
        4: { cellWidth: 25 },
        5: { cellWidth: 25 },
        6: { cellWidth: 25 },
        // etc
      },
    })
    doc.save(name + '.pdf')
    fetchSuccess()
  } catch (err) {
    fetchSuccess()
    alert('เกิดข้อผิดพลาด')
  }
}

export const DownloadPDFL = (data, name, fetchStart, fetchSuccess, alert) => {
  try {
    fetchStart()
    const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4', compressPdf: true })
    doc.setFont('thsarabunnew-webfont', 'normal')
    doc.setFont('thsarabunnew-webfont', 'bold')

    autoTable(doc, {
      head: [
        [
          {
            content: 'รายงานจำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
        [
          {
            content: 'วันที่ ' + data.startDate + ' ถึงวันที่ ' + data.endDate,
            styles: { halign: 'center', valign: 'middle' },
          },
        ],
      ],
      headerStyles: {
        font: 'thsarabunnew-webfont',
        fontStyle: 'bold',
        fillColor: '#FFF',

        fontSize: 10,
        textColor: '#000',
      },
    })
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [
        [
          {
            content: 'จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS',
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'จำนวนบทความทั้งหมด ' + data.total + ' บทความ',
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'จำนวนบทความที่อยู่ในวารสาร Q1 จำนวน ' + data.totalq1 + ' บทความ',
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'จำนวนอาจารย์และนักวิจัย ' + data.totalresearcher + ' คน',
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ใน SCOPUS ' + data.avgSCOPUS,
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
        [
          {
            content: 'ข้อมูล ณ วันที่ ' + data.dateNow,
            styles: { halign: 'right', valign: 'middle' },
          },
        ],
      ],
      headerStyles: {
        font: 'thsarabunnew-webfont',
        fontStyle: 'bold',
        fillColor: '#FFF',
        lineColor: '#f6eaf1',
        lineWidth: 0.3,
        fontSize: 10,
        textColor: '#000',
      },
    })
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY,
      head: [
        [
          { content: 'ลำดับที่', colSpan: 1, rowSpan: 1, styles: { halign: 'center', valign: 'middle', cellWidth: 15 } },
          {
            content: 'ชื่อผลงานวิจัย',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 40 },
          },
          {
            content: 'ชื่อผู้เขียนหลัก/ร่วมเขียน',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 40 },
          },
          { content: 'ปี', colSpan: 1, rowSpan: 1, styles: { halign: 'center', valign: 'middle', cellWidth: 15 } },
          {
            content: 'ชื่อวารสารที่ตีพิมพ์',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 40 },
          },
          { content: 'Link', colSpan: 1, rowSpan: 1, styles: { halign: 'center', valign: 'middle', cellWidth: 44 } },
          {
            content: 'Journal Ranking by SJR',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 45 },
          },
          {
            content: 'Q economics',
            colSpan: 1,
            rowSpan: 1,
            styles: { halign: 'center', valign: 'middle', cellWidth: 30 },
          },
        ],
      ],
      body: (() => {
        let l = []

        _.map([...data.data], e => {
          l.push([
            { content: e.index, colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 15 } },
            { content: e['title'], colSpan: 1, rowSpan: 1, styles: { halign: 'left', cellWidth: 40 } },
            { content: e['author'], colSpan: 1, rowSpan: 1, styles: { halign: 'left', cellWidth: 40 } },
            { content: e['year'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 15 } },
            { content: e['publicationName'], colSpan: 1, rowSpan: 1, styles: { halign: 'left', cellWidth: 40 } },
            { content: e['Link'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 44 } },
            { content: e['Journal'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 45 } },
            { content: e['econJournal'], colSpan: 1, rowSpan: 1, styles: { halign: 'center', cellWidth: 30 } },
          ])
        })
        return l
      })(),

      headerStyles: {
        font: 'thsarabunnew-webfont',
        fontStyle: 'bold',
        fillColor: '#e5e5e5',
        lineColor: '#f6eaf1',
        lineWidth: 0.3,
        fontSize: 10,
        textColor: '#000',
      },
      bodyStyles: {
        font: 'thsarabunnew-webfont',
        fontStyle: 'normal',
        lineColor: '#f6eaf1',
        lineWidth: 0.3,
        fontSize: 10,
        textColor: '#000',
      },
      tableWidth: 182,
      columnStyles: {
        0: { cellWidth: 15 },
        1: { cellWidth: 60 },
        2: { cellWidth: 45 },
        3: { cellWidth: 15 },
        4: { cellWidth: 45 },
        5: { cellWidth: 44 },
        6: { cellWidth: 45 },
        // etc
      },
    })
    doc.save(name + '.pdf')
    fetchSuccess()
  } catch (err) {
    fetchSuccess()
    alert('เกิดข้อผิดพลาด')
  }
}
export default DownloadPDFP
