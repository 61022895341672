import React from 'react'
import { People, InsertDriveFile, Security, PostAdd } from '@material-ui/icons'
import IntlMessages from '../../../utils/IntlMessages'

export const sidebarNavs = [
  {
    name: <IntlMessages id={'pages.manageLecturersInformation'} />,
    type: 'item',
    icon: <People />,
    link: '/manageLecturers',
    childrenlink: ['/managelecturersinfo'],
  },
  {
    name: <IntlMessages id={'pages.publicationReport'} />,
    type: 'item',
    icon: <InsertDriveFile />,
    link: '/publicationreport',
    childrenlink: ['/publicationreportInfo'],
  },
  {
    name: <IntlMessages id={'pages.citationReport'} />,
    type: 'item',
    icon: <InsertDriveFile />,
    link: '/citationreport',
    childrenlink: ['/citationreportInfo'],
  },
  {
    name: <IntlMessages id={'pages.manageAdmin'} />,
    type: 'item',
    icon: <Security />,
    link: '/manageadmin',
    childrenlink: ['/manageadmininfo'],
  },
]

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
]

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
]
