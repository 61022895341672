import React, { useEffect, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import { Grid } from '@material-ui/core'
import { fetchStart, fetchSuccess } from '../../../redux/actions'
import * as fn from '../../../services/default.service'
import { getHttp, postHttp } from '../../../services/http'
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
import Report from './report'
import moment from 'moment'
import FormSearchReport from '../../../components/formSearchReport'
import _ from 'lodash'

const Index = () => {
  const dispatch = useDispatch()
  const { setAlertDialog, setAlert } = useContext(AppContext)
  const tableRef = useRef(null)
  const [state, setState] = React.useState({
    startYear: new Date().getUTCFullYear(),
    endYear: new Date().getUTCFullYear(),
    starQuarter: new Date(),
    endQuarter: new Date(),
    checkedScopusId: true,
  })

  const [reportData, setReport] = React.useState({
    isView: false,
    data: {},
  })

  const [lecturer, setLecturer] = React.useState([])

  const getLecturer = async isCurrent => {
    dispatch(fetchStart())
    let user = await getHttp('/report/Lecturers', dispatch)
    if (user.data.status) {
      let tbLecturers = user.data.tbLecturers
      let lecturersList = []
      _.filter([...tbLecturers], (e, i) => {
        lecturersList.push({
          id: e.id,
          name: e.fullNameEng,
          scopusID: e.scopusID,
          firstNameEng: e.firstNameEng,
          lastNameEng: e.lastNameEng,
          firstInitial: fn.IsNullOrEmpty(e.firstInitial) ? '' : e.firstInitial,
        })
      })
      if (isCurrent) {
        setLecturer(lecturersList)
      }
    }
    dispatch(fetchSuccess())
  }

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    if (name === 'startYear') {
      let starQuarter = new Date(state.starQuarter.setFullYear(value))
      let endQuarter =
        value > state.endYear
          ? new Date(state.endQuarter.setFullYear(value))
          : new Date(state.endQuarter.setFullYear(state.endYear))
      setState({
        ...state,
        [name]: value,
        starQuarter: starQuarter,
        endYear: value > state.endYear ? value : state.endYear,
        endQuarter: starQuarter < endQuarter ? endQuarter : starQuarter,
      })
    } else if (name === 'endYear') {
      setState({
        ...state,
        [name]: value,
        startYear: value < state.startYear ? value : state.startYear,
        starQuarter:
          value < state.startYear
            ? state.endQuarter
            : state.endQuarter < state.starQuarter
            ? state.endQuarter
            : state.starQuarter,
        endQuarter: new Date(state.endQuarter.setFullYear(value)),
      })
    } else {
      setState({
        ...state,
        [name]: value,
      })
    }
  }

  const search = async () => {
    setReport({
      isView: false,
      data: {},
    })
    let parameter = {
      startYear: state.startYear,
      endYear: state.endYear,
      starQuarter: moment(state.starQuarter).format('yyyy-MM-DD'),
      endQuarter: moment(state.endQuarter).format('yyyy-MM-DD'),
      isScopusId: state.checkedScopusId,
    }
    let query = []
    if (fn.IsNullOrEmpty(state.lecturers)) {
      if (!state.checkedScopusId) {
        _.map([...lecturer], (e, i) => {
          query.push(e.lastNameEng + ' ' + e.firstInitial)
        })
      } else {
        _.map([...lecturer], (e, i) => {
          if (!fn.IsNullOrEmpty(e.scopusID)) {
            query.push(e.scopusID)
          }
        })
      }
    } else {
      if (!state.checkedScopusId) {
        _.map([...state.lecturers], (e, i) => {
          query.push(e.lastNameEng + ' ' + e.firstInitial)
        })
      } else {
        _.map([...state.lecturers], (e, i) => {
          query.push(e.scopusID)
        })
      }
    }
    parameter.query = query
    dispatch(fetchStart())
    let _publicationreport = await postHttp('/report/publicationreport', parameter)

    if (_publicationreport.status) {
      if (_publicationreport.data.status) {
        let lecturers_count = 0
        if (fn.IsNullOrEmpty(state.lecturers)) {
          lecturer.map(e => {
            let name = (e.lastNameEng + ' ' + e.firstInitial).toLowerCase()
            let _isempty = true
            _publicationreport.data.payload.data.map(j => {
              if (j.author.toLowerCase().includes(name)) {
                _isempty = false
              }
            })
            if (!_isempty) {
              lecturers_count = lecturers_count + 1
            }
          })
        } else {
          state.lecturers.map(e => {
            let name = (e.lastNameEng + ' ' + e.firstInitial).toLowerCase()
            let _isempty = true
            _publicationreport.data.payload.data.map(j => {
              if (j.author.toLowerCase().includes(name)) {
                _isempty = false
              }
            })
            if (!_isempty) {
              lecturers_count = lecturers_count + 1
            }
          })
        }
        _publicationreport.data.payload.totalresearcher = lecturers_count
        _publicationreport.data.payload.avgSCOPUS =
          _publicationreport.data.payload.data.length == 0
            ? '0.00'
            : (_publicationreport.data.payload.data.length / _publicationreport.data.payload.totalresearcher).toFixed(2)

        setReport({
          isView: true,
          data: _publicationreport.data.payload,
        })
        tableRef.current.dataManager.changePageSize(_publicationreport.data.payload.data.length || 1)
        setAlert({ open: true, type: 'success', msg: 'ค้นข้อมูลสำเร็จ' })
      } else {
        setAlertDialog({
          open: true,
          type: 'alert',
          msg: 'ค้นข้อมูล ไม่สำเร็จ',
          Close: even => {
            even()
          },
        })
      }
    } else {
      setAlertDialog({
        open: true,
        type: 'alert',
        msg: 'ค้นข้อมูล ไม่สำเร็จ',
        Close: even => {
          even()
        },
      })
    }
    dispatch(fetchSuccess())
  }

  const setStatelecturers = state => {
    setState({ ...state })
  }

  useEffect(() => {
    let isCurrent = true
    getLecturer(isCurrent)
    return () => {
      isCurrent = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <PageContainer heading={<IntlMessages id="pages.publicationReport" />}>
      <GridContainer>
        <FormSearchReport
          state={state}
          handleChange={handleChange}
          lecturer={lecturer}
          search={search}
          setStatelecturers={setStatelecturers}
        />
        {reportData.isView ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              padding: '0 20px 0 20px',
            }}>
            <Report
              data={reportData.data}
              fetchStart={() => {
                dispatch(fetchStart())
              }}
              fetchSuccess={() => {
                dispatch(fetchSuccess())
              }}
              alert={text => {
                setAlertDialog({
                  open: true,
                  type: 'alert',
                  msg: text,
                  Close: even => {
                    even()
                  },
                })
              }}
              tableRef={tableRef}
            />
          </Grid>
        ) : null}
      </GridContainer>
    </PageContainer>
  )
}

export default Index
