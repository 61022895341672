import React, { useEffect, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import { Grid, TextField, InputAdornment, Button, Box } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import { NavLink } from 'react-router-dom'
import * as fn from '../../../services/default.service'
import { makeStyles } from '@material-ui/styles'
import { getHttp, postHttp } from '../../../services/http'
import { fetchStart, fetchSuccess } from '../../../redux/actions'
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
import MaterialTableUC from '../../../components/MaterialTableUC'
import _ from 'lodash'
const useStyles = makeStyles(theme => ({
  search: {
    '& .MuiInputBase-root,input': {
      height: '30px',
      borderRadius: '20px',
      fontSize: '14px',
    },
    width: '100%',
    maxWidth: '600px',
  },
}))
const Index = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { setAlertDialog, setAlert } = useContext(AppContext)
  const tableRef = useRef(0)
  const columns = [
    {
      title: 'ลำดับ',
      field: 'index',
      cellStyle: { maxWidth: '80px', width: '80px', textAlign: 'center' },
      maxWidth: '80px',
      width: '80px',
      headerStyle: { maxWidth: '80px', width: '80px', textAlign: 'center', fontWeight: 'bold' },
    },
    {
      title: 'ชื่อผู้ใช้',
      field: 'userName',
      cellStyle: { maxWidth: 'auto', width: 'auto' },
      maxWidth: 'auto',
      width: 'auto',
      headerStyle: { maxWidth: 'auto', width: 'auto', fontWeight: 'bold' },
    },
    {
      title: 'อีเมล',
      field: 'email',
      cellStyle: { maxWidth: 'auto', width: 'auto' },
      maxWidth: 'auto',
      width: 'auto',
      headerStyle: { maxWidth: 'auto', width: 'auto', fontWeight: 'bold' },
    },
    {
      title: 'ชื่อ นามสกุล',
      field: 'fullName',
      cellStyle: { maxWidth: 'auto', width: 'auto' },
      maxWidth: 'auto',
      width: 'auto',
      headerStyle: { maxWidth: 'auto', width: 'auto', fontWeight: 'bold' },
    },

    {
      title: 'จัดการ',
      render: (e, r) => {
        return (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box style={{ padding: '0 5px' }}>
              <NavLink to={'/manageadminInfo/' + e.id} style={{ color: '#000' }}>
                <Edit />
              </NavLink>
            </Box>
            <Box style={{ padding: '0 5px' }}>
              <DeleteOutline
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // console.log(e)
                  setAlertDialog({
                    open: true,
                    type: 'confirm',
                    msg: 'ต้องการลบข้อมูล ใช่หรือไม่?',
                    Close: even => {
                      even()
                    },
                    Ok: even => {
                      even()
                      doDelete(e.id)
                    },
                  })
                }}
              />
            </Box>
          </Box>
        )
      },
      cellStyle: { maxWidth: '80px', width: '80px' },
      maxWidth: '80px',
      width: '80px',
      headerStyle: { maxWidth: '80px', width: '80px', fontWeight: 'bold' },
    },
  ]
  const [data, setdata] = React.useState([])
  const [list, setlist] = React.useState([])
  const [searchText, setsearchText] = React.useState()
  const changeSearchText = e => {
    let text = e.target.value
    if (fn.IsNullOrEmpty(text)) {
      setsearchText(null)
      setlist(data)
      // tableRef.current.dataManager.changePageSize(data.length || 1)
    } else {
      setsearchText(text)
      let list = _.filter([...data], (e, i) => {
        if (
          e.fullName.includes(text) ||
          e.email.includes(text) ||
          e.userName.includes(text) ||
          (i + 1).toString().includes(text)
        ) {
          return e
        }
      })
      setlist(list)
      // tableRef.current.dataManager.changePageSize(list.length || 1)
    }
  }
  const getUser = async isCurrent => {
    dispatch(fetchStart())
    let user = await getHttp('/users', dispatch)
    if (user.data.status) {
      let tbUser = user.data.tbUser
      _.filter([...tbUser], (e, i) => {
        e.index = i + 1
      })
      // tableRef.current.dataManager.changePageSize(tbUser.length || 1)

      setTimeout(() => {
        setdata(tbUser)
        setlist(tbUser)
      }, 500)
    }
    dispatch(fetchSuccess())
  }
  const doDelete = async (Id, callback) => {
    dispatch(fetchStart())
    let _delete = await postHttp('/users/delete', { id: Id })
    dispatch(fetchSuccess())
    if (_delete.status) {
      if (_delete.data.status) {
        getUser()
        setAlert({ open: true, type: 'success', msg: 'ลบข้อมูลสำเร็จ' })
      } else {
        setAlertDialog({
          open: true,
          type: 'alert',
          msg: 'ลบข้อมูล ไม่สำเร็จ',
          Close: even => {
            even()
          },
        })
      }
    } else {
      setAlertDialog({
        open: true,
        type: 'alert',
        msg: 'ลบข้อมูล ไม่สำเร็จ',
        Close: even => {
          even()
        },
      })
    }
  }
  useEffect(() => {
    let isCurrent = true
    getUser(isCurrent)
    return () => {
      isCurrent = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <PageContainer heading={<IntlMessages id="pages.manageAdmin" />}>
      <GridContainer>
        <Grid item xs={12} sm={8}>
          <TextField
            id="input-with-icon-search"
            type="search"
            className={classes.search}
            onChange={changeSearchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fontSize: '14px' }} />
                </InputAdornment>
              ),
            }}
            placeholder="search"
            defaultValue={searchText}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}>
          <NavLink to="/manageadminInfo" style={{ color: '#FFF' }}>
            <Button variant="contained" color="primary">
              <IntlMessages id="appModule.add" />
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={12}>
          <MaterialTableUC columns={columns} list={list} tableRef={tableRef} pagesize={true} />
        </Grid>
      </GridContainer>
    </PageContainer>
  )
}

export default Index
