import React, { useState } from 'react'
import { IconButton, Popover, Box } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CmtCard from '../../../../../@coremat/CmtCard'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useDispatch } from 'react-redux'
import { setAuthUser } from '../../../../../redux/actions/Auth'
import { getHttp } from '../../../../../services/http'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons'
const useStyles = makeStyles(() => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 14,
      paddingBottom: 14,
    },
    backgroundColor: '#f7eaf0',
  },
  perfectScrollbarLanguage: {
    // height: 324,
  },
  menuItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 205,
    },
  },
  item: {
    height: '35px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#edc2d6',
    },
  },
}))

const LanguageSwitcher = () => {
  const classes = useStyles()
  // const { locale, setLocale } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null)
  const [name, setname] = useState(null)
  const dispatch = useDispatch()

  const handleClick = event => {
    // let evn = event.currentTarget
    getHttp('/users/' + localStorage.getItem('auth'), dispatch).then(user => {
      let _name = ''
      if (user.status) {
        if (user.data.status) {
          _name = user.data.tbUser.firstName + ' ' + user.data.tbUser.lastName
        }
      }
      setname(_name)
    })

    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'language' : undefined

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClick} data-tut="reactour__localization">
        {/* <CmtImage src={flags[locale.locale]} /> */}
        <AccountCircleIcon
          style={{
            width: '30px',
            height: '30px',
            color: '#FFF',
          }}></AccountCircleIcon>
      </IconButton>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <Box
            style={{
              padding: '20px 10px',
              textAlign: 'center',
              // borderBottom: "1px solid #edc2d6",
              fontWeight: 'bold',
              fontSize: '14px',
            }}>
            {name}
          </Box>
          <Box
            style={{
              borderBottom: '1px solid #edc2d6',
              margin: '0 15px',
            }}></Box>
          <Box>
            <Box className={classes.item} style={{ marginTop: '10px', padding: '0 0 0 25px', cursor: 'pointer' }}>
              <NavLink
                to={'/manageadminInfo/' + localStorage.getItem('auth')}
                style={{ color: '#000' }}
                onClick={() => {
                  setAnchorEl(null)
                }}>
                <Box style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    style={{
                      fontSize: '20px',
                      color: '#000',
                      width: '1rem',
                    }}
                  />

                  <Box style={{ padding: ' 0 10px 0 10px', color: '#000' }}>Profile</Box>
                </Box>
              </NavLink>
            </Box>
            <Box
              className={classes.item}
              style={{
                // marginTop: '19px',
                marginBottom: '15px',
                padding: '0 0 0 25px',
                color: '#000',
                cursor: 'pointer',
              }}
              onClick={() => {
                // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
                localStorage.removeItem('token')
                localStorage.removeItem('auth')
                dispatch(setAuthUser(null))
              }}>
              <Box style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{
                    fontSize: '20px',
                    color: '#000',
                    width: '1rem',
                  }}
                />
                <Box style={{ padding: ' 0 10px 0 10px' }}>Logout</Box>
              </Box>
            </Box>
          </Box>
        </CmtCard>
      </Popover>
    </React.Fragment>
  )
}

export default LanguageSwitcher
