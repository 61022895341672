import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import { NavLink } from 'react-router-dom'
import * as fn from '../../../services/default.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons'

import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { DownloadPDFL } from './downloadPDF'
import DownloadExcel from './downloadExcel'
import { PatchedPagination } from '../../../components/MaterialTableUC'
import moment from 'moment'
const useStyles = makeStyles(theme => ({
  reportButtonRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  reportButton: {
    width: '160px',
    height: '30px',
    margin: '0 0 0 10px',
    backgroundColor: '#f6eaf1',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    WebkitUserSelect: 'none' /* Safari */,
    MsUserSelect: 'none' /* IE 10 and IE 11 */,
    userSelect: 'none' /* Standard syntax */,
    cursor: 'pointer',
  },
  head: {
    width: '100%',
    fontSize: '14px',
  },
  headline: {
    width: '100%',
    height: '40px',
    border: '1px solid #f6eaf1',
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    justifyContent: 'end',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  tableRoot: {
    width: '100%',
    marginBottom: '25px',
    '& .MuiTableHead-root .MuiTableCell-root': {
      backgroundColor: '#e5e5e5',
    },
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
    '& tbody.MuiTableBody-root,.MuiTableHead-root': {
      // border: "1px solid rgba(224, 224, 224, 1)"
    },
    '& .MuiTableFooter-root .MuiTableCell-footer': {
      border: 'none',
    },
    '& tbody.MuiTableBody-root,td,th': {
      border: '1px solid #f6eaf1',
    },
    '& .MuiPaper-rounded div': {
      overflowY: 'unset !important',
    },
  },
  textNUll: {
    '& .MuiTableCell-root.MuiTableCell-body': {
      color: '#FFF',
    },
  },
  IsPageSizeNull: {
    '& .MuiTableFooter-root .MuiTableCell-footer': {
      border: 'none',
    },
    '& .MuiTablePagination-toolbar': {
      display: 'none',
    },
  },
}))
const Report = ({ data, tableRef }) => {
  const classes = useStyles()
  const columns = [
    {
      title: 'ลำดับ',
      field: 'index',
      cellStyle: { maxWidth: '80px', width: '80px' },
      maxWidth: '80px',
      width: '80px',
      headerStyle: {
        maxWidth: '80px',
        width: '80px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'ชื่อผลงานวิจัย',
      field: 'title',
      cellStyle: { maxWidth: 'auto', width: 'auto', wordWrap: 'break-word' },
      maxWidth: 'auto',
      width: 'auto',
      headerStyle: {
        maxWidth: 'auto',
        width: 'auto',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'ชื่อผู้เขียนหลัก/ร่วมเขียน',
      field: 'author',
      cellStyle: { minWidth: '210px', width: 'auto', wordWrap: 'break-word' },
      minWidth: '210px',
      width: 'auto',
      headerStyle: {
        minWidth: '210px',
        width: 'auto',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'ปี',
      field: 'year',
      cellStyle: { maxWidth: '80px', width: '80px' },
      maxWidth: '80px',
      width: '80px',
      headerStyle: {
        maxWidth: '80px',
        width: '80px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    {
      title: 'ชื่อวารสารที่ตีพิมพ์',
      field: 'publicationName',
      cellStyle: { minWidth: '200px', width: 'auto', wordWrap: 'break-word' },
      minWidth: '200px',
      width: 'auto',
      headerStyle: {
        minWidth: '200px',
        width: 'auto',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'Link',
      field: 'Link',
      cellStyle: { maxWidth: '80px', width: '80px' },
      maxWidth: '80px',
      width: '80px',
      headerStyle: {
        maxWidth: '80px',
        width: '80px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
      render: (e, r) => {
        return (
          <Box style={{ display: 'flex' }}>
            <Box>
              <NavLink to={e.Link} style={{ color: 'blue' }}>
                Link
              </NavLink>
            </Box>
          </Box>
        )
      },
    },
    {
      title: 'Journal Ranking by SJR',
      field: 'Journal',
      cellStyle: { minWidth: '225px', width: '225px', textAlign: 'center' },
      minWidth: '225px',
      width: '225px',
      headerStyle: {
        minWidth: '225px',
        width: '225px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'Q economics',
      field: 'econJournal',
      cellStyle: { minWidth: '135px', width: '135px', textAlign: 'center' },
      minWidth: '135px',
      width: '135px',
      headerStyle: {
        minWidth: '135px',
        width: '135px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
  ]

  return (
    <Box style={{ width: '100%' }}>
      <Box style={{ width: '100%', marginBottom: '10px' }}>
        <Box className={classes.reportButtonRoot}>
          <Box
            className={classes.reportButton}
            onClick={() => {
              // DownloadPDFP(data, 'Citation Report')
              DownloadPDFL(data, 'Citation Report')
            }}>
            <FontAwesomeIcon icon={faFilePdf} color="#ff3823" />
            <Box style={{ padding: '0 5px', fontSize: '12px' }}>Export To PDF File</Box>
          </Box>
          <Box
            className={classes.reportButton}
            onClick={() => {
              DownloadExcel(data, 'Citation Report')
            }}>
            <FontAwesomeIcon icon={faFileExcel} color="#00733b" />
            <Box style={{ padding: '0 5px', fontSize: '12px' }}>Export To Excel File</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.head}>
        <Box className={classes.headline}>จำนวน Citations ต่ออาจารย์ และนักวิจัย ที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS</Box>
        <Box className={classes.headline}>จำนวน Citations ทั้งหมด {data.total} บทความ</Box>
        <Box className={classes.headline}>
          จำนวนอาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS {data.totalresearcher} คน
        </Box>
        <Box className={classes.headline}>
          จำนวนการอ้างอิงผลงานวิจัย (Citations) ในฐานข้อมูล Scopus เท่ากับ {data.avgSCOPUS} คน
        </Box>
        {/* <Box className={classes.headline}>จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ใน SCOPUS {data.avgSCOPUS}</Box> */}
        <Box className={classes.headline}>{'ข้อมูล ณ วันที่ ' + data.dateNow}</Box>
      </Box>
      <Box
        className={
          classes.tableRoot + ' ' + classes.IsPageSizeNull + ' ' + (data.data.length > 0 ? '' : ' ' + classes.textNUll)
        }>
        <MaterialTable
          tableRef={tableRef}
          id="Data-Table"
          icons={fn.tableIcons}
          title="3"
          columns={columns}
          data={data.data}
          options={{
            toolbar: false,
          }}
          components={{
            Header: props => {
              return (
                <TableHead>
                  <TableRow>
                    <TableCell rowSpan={2}>ลำดับ</TableCell>
                    <TableCell colSpan={4} align="center">
                      Publication{' '}
                    </TableCell>
                    <TableCell colSpan={3} align="center">
                      Cited By
                    </TableCell>
                    <TableCell rowSpan={2} align="center">
                      ฐานข้อมูลสากล
                    </TableCell>
                    <TableCell rowSpan={2} align="center">
                      แหล่งอ้างอิง
                    </TableCell>
                    <TableCell rowSpan={2} align="center">
                      Q economics
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Year</TableCell>
                    <TableCell align="center">Title</TableCell>
                    <TableCell align="center">Authors</TableCell>
                    <TableCell align="center">Journal</TableCell>
                    <TableCell align="center">Title</TableCell>
                    <TableCell align="center">Journal</TableCell>
                    <TableCell align="center">Authors</TableCell>
                  </TableRow>
                </TableHead>
              )
            },
            Row: ({ data }) => {
              return (
                <TableRow>
                  <TableCell>{data.index}</TableCell>
                  <TableCell>{data['Publication:year']}</TableCell>
                  <TableCell>{data['Publication:title']}</TableCell>
                  <TableCell>{data['Publication:author']}</TableCell>
                  <TableCell>{data['Publication:Journal']}</TableCell>
                  <TableCell>{data['CitedBy:title']}</TableCell>
                  <TableCell>{data['CitedBy:Journal']}</TableCell>
                  <TableCell>{data['CitedBy:author']}</TableCell>
                  <TableCell>{data['CitedBy:international']}</TableCell>
                  <TableCell>
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <Box>
                        <p>
                          <a href={data['CitedBy:link']} target="_blank" rel="noreferrer">
                            Link
                          </a>
                        </p>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">{data['econJournal']}</TableCell>
                </TableRow>
              )
            },
            Pagination: PatchedPagination,
          }}
        />
      </Box>
    </Box>
  )
}

export default Report
