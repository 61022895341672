import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import {
  Grid,
  Box,
  FormControl,
  TextField,
  OutlinedInput,
  // , FormHelperText
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useParams } from 'react-router-dom'
import * as fn from '../../../services/default.service'
import { getHttp, postHttp } from '../../../services/http'
import { fetchStart, fetchSuccess } from '../../../redux/actions'
import Button from '@material-ui/core/Button'
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  formControl: {
    height: '30px',
    width: '400px',
    '& .MuiInputBase-formControl': {
      height: '30px',
    },
    '& .MuiSelect-select': {
      padding: '0 10px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 5px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -5px) scale(.5)',
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '10px',
      margin: '0',
      color: 'transparent',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#f44336',
    },
    '& input': {
      fontSize: '14px',
      height: '30px',
      padding: '0px 10px',
    },
    '& fieldset': { borderColor: '#cb1b67 !important' },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .Mui-disabled fieldset': {
      background: '#ebebeb',
    },
  },
  lbl: {
    display: 'flex',
    alignItems: 'center',
    // padding: "0 10px",
    width: 'calc(100% - 400px)',
    minWidth: '120px',
    maxWidth: '150px',
  },
  backButton: {
    border: '1px solid #cb1b67',
    backgroundColor: '#FFF',
    '& .MuiButton-label': {
      color: '#cb1b67',
    },
    '&:hover': {
      border: '1px solid #cb1b67',
      backgroundColor: '#FFF',
    },
  },
}))
const Info = () => {
  const { setAlertDialog, setAlert } = useContext(AppContext)
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { Id } = useParams()
  const [data, setdata] = React.useState({
    id: null,
    modefy: false,
    editPassword: false,
    userName: {
      invalid: false,
      text: '',
      msg: '',
    },
    password: {
      invalid: false,
      text: '',
      msg: '',
    },
    confirmPassword: {
      invalid: false,
      text: '',
      msg: '',
    },
    firstName: {
      invalid: false,
      text: '',
      msg: '',
    },
    lastName: {
      invalid: false,
      text: '',
      msg: '',
    },
    email: {
      invalid: false,
      text: '',
      msg: '',
    },
    showPassword: false,
  })

  const onChange = e => {
    let name = e.target.name
    data.modefy = true
    if ('password,confirmPassword'.includes(name)) {
      let password = data['password']
      password.invalid = false
      let confirmPassword = data['confirmPassword']
      confirmPassword.invalid = false
      if (name === 'password') {
        password.text = e.target.value
      } else {
        confirmPassword.text = e.target.value
      }
      if (!fn.IsNullOrEmpty(password.text) && !fn.IsNullOrEmpty(!confirmPassword.text)) {
        if (data.confirmPassword.text !== data.password.text) {
          confirmPassword.msg = 'Confirm ไม่ถูกต้อง'
          confirmPassword.invalid = true
          password.msg = ''
          password.invalid = true
          setdata({ ...data, confirmPassword: confirmPassword, password: password })
        }
      }
      setdata({ ...data })
    } else {
      let item = data[name]
      item.text = e.target.value
      item.invalid = false
      setdata({ ...data })
    }
  }
  const handleClickShowPassword = () => {
    setdata({ ...data, showPassword: !data.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const getUserById = async (isCurrent, Id) => {
    dispatch(fetchStart())
    let user = await getHttp('/users/' + Id, dispatch)
    if (user.data.status) {
      let tbUser = user.data.tbUser
      if (isCurrent) {
        setdata({
          ...data,
          modefy: false,
          userName: {
            text: tbUser.userName,
          },
          password: {
            text: tbUser.password,
          },
          confirmPassword: {
            text: tbUser.password,
          },
          firstName: {
            text: tbUser.firstName,
          },
          lastName: {
            text: tbUser.lastName,
          },
          email: {
            text: tbUser.email,
          },
        })
      }
    }
    dispatch(fetchSuccess())
  }
  const dosave = async callback => {
    if (valid()) {
      let item = {
        id: data.id,
        userName: data.userName.text,
        firstName: data.firstName.text,
        lastName: data.lastName.text,
        email: data.email.text,
        password: fn.IsNullOrEmpty(data.id) ? data.password.text : data.editPassword ? data.password.text : null,
        editPassword: fn.IsNullOrEmpty(data.id) ? true : data.editPassword,
      }
      dispatch(fetchStart())
      let _save = await postHttp('/users', item)
      if (_save.status) {
        if (_save.data.status) {
          if (callback !== undefined) {
            callback()
          } else {
            if (fn.IsNullOrEmpty(data.id)) {
              data.id = _save.data.id
              data.showPassword = false
              data.editPassword = false
              data.modefy = false

              setdata({ ...data })
              window.history.replaceState(null, null, '/manageadminInfo/' + _save.data.id)
              getUserById(true, _save.data.id)
            } else {
              data.showPassword = false
              data.editPassword = false
              data.modefy = false

              setdata({ ...data })
              getUserById(true, data.id)
            }
            setAlert({ open: true, type: 'success', msg: 'บันทึกข้อมูลสำเร็จ' })
          }
        } else {
          if (_save.data.isDupdate) {
            _.map([..._save.data.Dupdate], e => {
              if (e === 'Email') {
                let email = data.email
                email.msg = 'อีเมลซ้ำกับในระบบกรุณากรอกข้อมูลใหม่'
                email.invalid = true

                setdata({ ...data, email: email })
              } else if (e === 'userName') {
                let userName = data.userName
                userName.msg = 'Username ซ้ำกับในระบบกรุณากรอกข้อมูลใหม่'
                userName.invalid = true

                setdata({ ...data, userName: userName })
              }
            })
          } else {
            setAlertDialog({
              open: true,
              type: 'alert',
              msg: 'บันทึกข้อมูลสำเร็จ',
              Close: even => {
                even()
              },
            })
          }
        }
      } else {
        setAlertDialog({
          open: true,
          type: 'alert',
          msg: 'ลบข้อมูล ไม่สำเร็จ',
          Close: even => {
            even()
          },
        })
      }
      dispatch(fetchSuccess())
    }
  }
  const valid = () => {
    let valid = !0
    if (fn.IsNullOrEmpty(data.userName.text)) {
      let userName = data.userName
      userName.msg = 'กรุณาระบุ UserName'
      userName.invalid = true
      valid = !1
      setdata({ ...data, userName: userName })
    }
    if (fn.IsNullOrEmpty(data.firstName.text)) {
      let firstName = data.firstName
      firstName.msg = 'กรุณาระบุชื่อ'
      firstName.invalid = true
      valid = !1
      setdata({ ...data, firstName: firstName })
    }
    if (fn.IsNullOrEmpty(data.lastName.text)) {
      let lastName = data.lastName
      lastName.msg = 'กรุณาระบุนามสกุล'
      lastName.invalid = true
      valid = !1
      setdata({ ...data, lastName: lastName })
    }
    if (fn.IsNullOrEmpty(data.email.text)) {
      let email = data.email
      email.msg = 'กรุณาระบุอีเมล'
      email.invalid = true
      valid = !1
      setdata({ ...data, email: email })
    } else if (!fn.IsNullOrEmpty(data.email.text)) {
      if (!fn.validateEmail(data.email.text)) {
        let email = data.email
        email.msg = 'อีเมลไม่ถูกต้อง'
        email.invalid = true
        valid = !1
        setdata({ ...data, email: email })
      }
    }
    const checkPassword = () => {
      if (fn.IsNullOrEmpty(data.password.text)) {
        let password = data.password
        password.msg = 'กรุณาระบุ Password'
        password.invalid = true
        valid = !1
        setdata({ ...data, password: password })
      }
      if (fn.IsNullOrEmpty(data.confirmPassword.text)) {
        let confirmPassword = data.confirmPassword
        confirmPassword.msg = 'กรุณาระบุ Confirm Password'
        confirmPassword.invalid = true
        valid = !1
        setdata({ ...data, confirmPassword: confirmPassword })
      }
      if (!fn.IsNullOrEmpty(data.password.text) && !fn.IsNullOrEmpty(!data.confirmPassword.text)) {
        if (data.confirmPassword.text !== data.password.text) {
          let confirmPassword = data.confirmPassword
          let password = data.password
          confirmPassword.msg = 'Confirm ไม่ถูกต้อง'
          confirmPassword.invalid = true
          password.msg = ''
          password.invalid = true
          valid = !1
          setdata({ ...data, confirmPassword: confirmPassword, password: password })
        }
      }

      if (!fn.IsNullOrEmpty(data.password.text)) {
        if (data.password.text.length < 8) {
          valid = !1
        }
        if (!data.password.text.match(/[A-Z]/)) {
          valid = !1
        }
        if (!data.password.text.match(/[a-z]/)) {
          valid = !1
        }
        if (!data.password.text.match(/[\d`~!@#$%\\^&*()+=|;:'",.<>\\/?\\\\-]/)) {
          valid = !1
        }
      }
    }
    if (fn.IsNullOrEmpty(data.id)) {
      checkPassword()
    } else {
      if (data.editPassword) {
        checkPassword()
      }
    }
    return valid
  }
  useEffect(() => {
    let isCurrent = true
    if (!fn.IsNullOrEmpty(Id)) {
      data.id = Id

      setdata({ ...data })
      getUserById(isCurrent, Id)
    }
    return () => {
      isCurrent = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const breadcrumbs = [
    {
      label: (
        <Box style={{ display: 'flex' }}>
          <Button
            className={classes.backButton}
            variant="contained"
            color="primary"
            onClick={e => {
              e.preventDefault()
              if (data.modefy) {
                setAlertDialog({
                  open: true,
                  type: 'confirm',
                  msg: 'มีการแก้ไขต้องการบันทึก ใช่หรือไม่?',
                  Close: e => {
                    e()
                    history.push('/manageadmin')
                  },
                  Ok: e => {
                    e()
                    dosave(() => {
                      history.push('/manageadmin')
                    })
                  },
                })
              } else {
                history.push('/manageadmin')
              }
            }}>
            กลับ
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 10px' }}
            onClick={e => {
              e.preventDefault()
              dosave()
            }}>
            บันทึก
          </Button>
        </Box>
      ),
    },
  ]

  let colour1 = 'red',
    colour2 = 'red',
    colour3 = 'red',
    colour4 = 'red',
    colour5 = 'red'
  if ((data.editPassword || fn.IsNullOrEmpty(data.id)) && !fn.IsNullOrEmpty(data.password.text)) {
    if (data.password.text.length >= 8) {
      colour1 = 'green'
    }
    if (data.password.text.match(/[A-Z]/)) {
      colour2 = 'green'
    }
    if (data.password.text.match(/[a-z]/)) {
      colour3 = 'green'
    }
    if (data.password.text.match(/[\d`~!@#$%\\^&*()+=|;:'",.<>\\/?\\\\-]/)) {
      colour4 = 'green'
    }
    if (data.password.text === data.confirmPassword.text && data.password.text !== '') {
      colour5 = 'green'
    }
  }

  return (
    <PageContainer heading={<IntlMessages id="pages.manageAdmin" />} breadcrumbs={breadcrumbs}>
      <GridContainer style={{ padding: '0 15px 0 65px' }}>
        {/* <Grid item xs={12} style={{ padding: "0" }}> */}
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>
              Username <span style={{ color: 'red' }}>*</span>
            </Box>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="input-userName"
                name="userName"
                type="text"
                onChange={onChange}
                value={data.userName.text}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
                placeholder="Username"
                error={data.userName.invalid}
                helperText={data.userName.msg}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              data.editPassword = !data.editPassword
              setdata({ ...data })
            }}>
            เปลียน Password
          </Button>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                Password <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <OutlinedInput
                  id="input-password"
                  name="password"
                  disabled={fn.IsNullOrEmpty(data.id) ? false : data.editPassword ? false : true}
                  type={data.showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  value={data.password.text}
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {data.showPassword ? (
                          <VisibilityOff style={{ color: 'c9c9c9', width: '15px' }} />
                        ) : (
                          <Visibility style={{ color: 'c9c9c9', width: '15px' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="Password"
                  error={data.password.invalid}
                  // helperText={data.password.msg}
                />
                {/* {!!data.password.invalid && (
                  <FormHelperText error id="accountId-error">
                    {data.password.msg}
                  </FormHelperText>
                )} */}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {data.editPassword || fn.IsNullOrEmpty(data.id) ? (
          <Grid item xs={12} style={{ padding: '0' }}>
            <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
              <Box style={{ display: 'flex' }}>
                <Box className={classes.lbl}>{/* Password <span style={{ color: 'red' }}>*</span> */}</Box>
                <Box>
                  <Box style={{ color: colour1 }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: colour1 }} /> At least 8 characters
                  </Box>
                  <Box style={{ color: colour2 }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: colour2 }} /> At least 1 uppercase letter
                  </Box>
                  <Box style={{ color: colour3 }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: colour3 }} /> At least 1 lowercase letter
                  </Box>
                  <Box style={{ color: colour4 }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: colour4 }} /> At least 1 number or special
                    character
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                Confirm Password <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <OutlinedInput
                  id="input-password"
                  name="confirmPassword"
                  disabled={fn.IsNullOrEmpty(data.id) ? false : data.editPassword ? false : true}
                  type={data.showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  value={data.confirmPassword.text}
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {data.showPassword ? (
                          <VisibilityOff style={{ color: 'c9c9c9', width: '15px' }} />
                        ) : (
                          <Visibility style={{ color: 'c9c9c9', width: '15px' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Confirm Password"
                  inputProps={{
                    maxLength: 250,
                  }}
                  error={data.password.invalid}
                  // helperText={data.password.msg}
                />
                {/* {!!data.confirmPassword.invalid && (
                  <FormHelperText error id="accountId-error">
                    {data.confirmPassword.msg}
                  </FormHelperText>
                )} */}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {data.editPassword || fn.IsNullOrEmpty(data.id) ? (
          <Grid item xs={12} style={{ padding: '0' }}>
            <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
              <Box style={{ display: 'flex' }}>
                <Box className={classes.lbl}>{/* Password <span style={{ color: 'red' }}>*</span> */}</Box>
                <Box>
                  <Box style={{ color: colour5 }}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: colour5 }} /> Password == Confirm Password
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                อีเมล <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-email"
                  name="email"
                  type="text"
                  onChange={onChange}
                  value={data.email.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="อีเมล"
                  error={data.email.invalid}
                  helperText={data.email.msg}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                ชื่อ <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-firstName"
                  name="firstName"
                  type="text"
                  onChange={onChange}
                  value={data.firstName.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="ชื่อ"
                  error={data.firstName.invalid}
                  helperText={data.firstName.msg}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid item xs={12} sm={6} style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                นามสกุล <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-lastName"
                  name="lastName"
                  type="text"
                  onChange={onChange}
                  value={data.lastName.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="นามสกุล"
                  error={data.lastName.invalid}
                  helperText={data.lastName.msg}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </GridContainer>
    </PageContainer>
  )
}

export default Info
