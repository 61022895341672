import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { DownloadPDFL } from './downloadPDF'
import DownloadExcel from './downloadExcel'
import MaterialTableUC from '../../../components/MaterialTableUC'
import moment from 'moment'
const useStyles = makeStyles(theme => ({
  reportButtonRoot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  reportButton: {
    width: '160px',
    height: '30px',
    margin: '0 0 0 10px',
    backgroundColor: '#f6eaf1',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    WebkitUserSelect: 'none' /* Safari */,
    MsUserSelect: 'none' /* IE 10 and IE 11 */,
    userSelect: 'none' /* Standard syntax */,
    cursor: 'pointer',
  },
  head: {
    width: '100%',
    fontSize: '14px',
  },
  headline: {
    width: '100%',
    height: '40px',
    border: '1px solid #f6eaf1',
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    justifyContent: 'end',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  tableRoot: {
    width: '100%',
    marginBottom: '25px',
    '& .MuiTableHead-root .MuiTableCell-root': {
      backgroundColor: '#e5e5e5',
    },
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
    '& tbody.MuiTableBody-root,.MuiTableHead-root': {
      // border: "1px solid rgba(224, 224, 224, 1)"
    },
    '& .MuiTableFooter-root .MuiTableCell-footer': {
      border: 'none',
    },
    '& tbody.MuiTableBody-root,td,th': {
      border: '1px solid #f6eaf1',
    },
    '& .MuiPaper-rounded div': {
      overflowY: 'unset !important',
    },
  },
}))
const Report = ({ data, fetchStart, fetchSuccess, alert, tableRef }) => {
  // const dispatch = useDispatch();
  // const { setAlertDialog, setAlert } = useContext(AppContext);
  const classes = useStyles()
  const columns = [
    {
      title: 'ลำดับ',
      field: 'index',
      cellStyle: {
        maxWidth: '80px',
        width: '80px',
        textAlign: 'center',
      },
      maxWidth: '80px',
      width: '80px',
      headerStyle: {
        maxWidth: '80px',
        width: '80px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'ชื่อผลงานวิจัย',
      field: 'title',
      cellStyle: { maxWidth: 'auto', width: 'auto', wordWrap: 'break-word' },
      maxWidth: 'auto',
      width: 'auto',
      headerStyle: {
        maxWidth: 'auto',
        width: 'auto',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'ชื่อผู้เขียนหลัก/ร่วมเขียน',
      field: 'author',
      cellStyle: { minWidth: '210px', width: 'auto', wordWrap: 'break-word' },
      minWidth: '210px',
      width: 'auto',
      headerStyle: {
        minWidth: '210px',
        width: 'auto',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'ปี',
      field: 'year',
      cellStyle: { maxWidth: '80px', width: '80px', textAlign: 'center' },
      maxWidth: '80px',
      width: '80px',
      headerStyle: {
        maxWidth: '80px',
        width: '80px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    {
      title: 'ชื่อวารสารที่ตีพิมพ์',
      field: 'publicationName',
      cellStyle: { minWidth: '200px', width: 'auto', wordWrap: 'break-word' },
      minWidth: '200px',
      width: 'auto',
      headerStyle: {
        minWidth: '200px',
        width: 'auto',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'Link',
      field: 'Link',
      cellStyle: { maxWidth: '80px', width: '80px' },
      maxWidth: '80px',
      width: '80px',
      headerStyle: {
        maxWidth: '80px',
        width: '80px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
      render: (e, r) => {
        return (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box>
              <p>
                <a href={e.Link} target="_blank" rel="noreferrer">
                  Link
                </a>
              </p>
            </Box>
          </Box>
        )
      },
    },
    {
      title: 'Journal Ranking by SJR',
      field: 'Journal',
      cellStyle: { minWidth: '200px', width: '200px', textAlign: 'center' },
      minWidth: '200px',
      width: '200px',
      headerStyle: {
        minWidth: '200px',
        width: '200px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
    {
      title: 'Q economics',
      field: 'econJournal',
      cellStyle: { minWidth: '135px', width: '135px', textAlign: 'center' },
      minWidth: '135px',
      width: '135px',
      headerStyle: {
        minWidth: '135px',
        width: '135px',
        textAlign: 'center',
        height: '60px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '0 10px',
      },
    },
  ]
  return (
    <Box style={{ width: '100%' }}>
      <Box style={{ width: '100%', marginBottom: '10px' }}>
        <Box className={classes.reportButtonRoot}>
          <Box
            className={classes.reportButton}
            onClick={() => {
              DownloadPDFL(data, 'Publication Report', fetchStart, fetchSuccess, text => {
                alert(text)
              })
            }}>
            <FontAwesomeIcon icon={faFilePdf} color="#ff3823" />
            <Box style={{ padding: '0 5px', fontSize: '12px' }}>Export To PDF File</Box>
          </Box>
          <Box
            className={classes.reportButton}
            onClick={() => {
              DownloadExcel(data, 'Publication Report', fetchStart, fetchSuccess, text => {
                alert(text)
              })
            }}>
            <FontAwesomeIcon icon={faFileExcel} color="#00733b" />
            <Box style={{ padding: '0 5px', fontSize: '12px' }}>Export To Excel File</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.head}>
        <Box className={classes.headline}>จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ในฐานข้อมูล SCOPUS</Box>
        <Box className={classes.headline}>จำนวนบทความทั้งหมด {data.total} บทความ</Box>
        <Box className={classes.headline}>จำนวนบทความที่อยู่ในวารสาร Q1 จำนวน {data.totalq1} บทความ</Box>
        <Box className={classes.headline}>จำนวนอาจารย์และนักวิจัย {data.totalresearcher} คน</Box>
        <Box className={classes.headline}>จำนวนบทความต่ออาจารย์และนักวิจัยที่ได้รับการตีพิมพ์ใน SCOPUS {data.avgSCOPUS}</Box>
        <Box className={classes.headline}>{'ข้อมูล ณ วันที่ ' + data.dateNow}</Box>
      </Box>
      <Box className={classes.tableRoot}>
        <MaterialTableUC columns={columns} list={data.data} tableRef={tableRef} pagesize={false} />
      </Box>
    </Box>
  )
}

export default Report
